import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Flex,
  Text,
  Divider,
  Circle,
  Button,
  HStack,
} from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import { StaticImage } from 'gatsby-plugin-image';


const calculation = ['總金額', '總面積']

const list = (data) => data?.map((t, k) => (
  <Flex mt="0.75em" key={k}>
    <Circle mt={responsive('0.25rem', '0.125em')} mr={responsive('0.5rem', '1.5rem')} size="1em" fontSize={responsive('0.625em', '1.75em')} bg="darkPink" />
    <Text　fontFamily={'noto'} fontSize={responsive('0.75em', '1.5em')}>
      {t.match(/([^：]+)/g).length > 1 ? (
        <>
        <b>{t.match(/([^：]+)/g)[0]}：</b>
        <span>{t.match(/([^：]+)/g)[1]}</span>
        </>
      ) : (
        t
      )}
    </Text>
  </Flex>
))

const detail = (data, name) => (
  <Box>
    {data?.map((d, i) => (
      <Box key={i} pt={responsive('1em', '1.625em')}>
        <Flex alignItems={'center'}>
          <Text fontWeight={'bold'} fontSize={responsive('1.125em', '3.125em')}>0{i + 1}</Text>
          <Box m={responsive('0.125em 0.125em 0 0.375em', 0)} width={responsive('1em', '3em')}><StaticImage src="hamisphere.png" /></Box>
          <Text fontSize={responsive('0.75em', '1.875em')}>{i ? (d.title || '其他說明') : (d.title || `${name}資料包含`)}</Text>
        </Flex>
        {list(d.desc)}
      </Box>
    ))}
  </Box>
)

const Item = ({ isOpen, data, onClose }) => {
  const [calculate, setCalculation] = useState(0)
  return (
    <Modal size={'auto'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="bgGray" />
      <ModalContent pt={responsive('1.875em', '7.25em')} borderRadius="0" pb={responsive('1.25em', '2em')} width={responsive('60%', '60%')} maxWidth="52.5em" color="white" bg="bgPurple">
        <Flex ml={responsive('1.375em', '5.625em')}>
          <Box fontWeight={600} lineHeight={1.1} fontSize={responsive('1.5em', '4.375em')} width={'1em'}>
            {data?.name}
          </Box>
          <Box textAlign={'right'} mr={responsive('0', '1em')} ml={responsive('1.5em', '2em')} flex={1}>
            {data?.img}
          </Box>
        </Flex>
        <Box px={responsive('1em', '5.625em')} pt={responsive('1.25em', '2.5em')}>
          <Text fontFamily={'noto'} letterSpacing="2px" textAlign={responsive('left', 'center')} fontSize={responsive('0.75em', '1.75em')}>包含候選人的配偶及未成年子女各別所有之財產。</Text>
          <Divider my={responsive('1em', '2.375rem')} />
          <Text fontWeight={600} textAlign={'center'} letterSpacing="4px" fontSize={responsive('1em', '2.5em')}>中選會訂定資料說明</Text>
          {detail(data?.detail, data?.name)}
          {data.head && (
            <Box>
              <Divider my={responsive('1em', '2.375rem')} />
              <Text letterSpacing="4px" fontWeight={700} fontSize={responsive('1em', '2.5em')}>
                {data.head}{'總面積'}
              </Text>
              <Text letterSpacing="4px" fontWeight={500} fontFamily="noto" fontSize={responsive('0.75em', '1.875em')}>
                本網站資料統計、加總計算方式
              </Text>
              {detail(data.area)}
              {list(data.way)}
            </Box>
          )}
        </Box>
        <Box
          fontFamily={'noto'}
          color="bgPurple"
          bg="rgba(255, 255, 255, 0.6)"
          mt={responsive('1.375em', '3.875rem')}
          mx={responsive('1.125em', '4.25rem')}
          px={responsive('1em', '3.125rem')}
          pt={responsive('1em', '3.5rem')}
          pb={responsive('1em', '2.5rem')}
          fontSize={responsive('0.75em', '1.5em')}
          fontWeight={500}
        >
          ＊本網站僅呈現候選人部分申報資料，完整資料可至中選會網站下載。<br />
          <br />
          ＊申報人（候選人）之配偶及未成年子女各別所有之財產，符合公職人員財產申報法所定應申報之標準者，應由申報人（候選人）一併申報。
        </Box>
        <ModalCloseButton
          top={responsive('0.5rem', '2.625rem')}
          right={responsive('0.5rem', '1.875rem')}
          width={responsive('1.375rem', '2em')}
          height={responsive('1.375rem', '2em')}
          fontSize={responsive('0.5em', '1.5em')}
          borderRadius={'full'}
          bg="white"
          color="bgPurple"
          _hover={{ color: 'bgPurple' }}
          _active={{}}
        />
        {/* {data.area && (
          <HStack spacing={2} pos="absolute" left={responsive(0, '6px')} top="0" transform={'translateY(-50%)'}>
            {calculation.map((cal, i) => (
              <Button
                bg={calculate === i ? 'bgPurple' : 'darkPurple'}
                variant={'purple'}
                onClick={() => setCalculation(i)}
                key={i}
              >{cal}</Button>
            ))}
          </HStack>
        )} */}
      </ModalContent>
    </Modal>
  )
}

export default Item
