import SVG from 'components/SVG'
import React from 'react'

const Insurance = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.6,42.27l0,0A61.14,61.14,0,0,0,441.48,77h0c52-24.7,106.15,29.5,81.46,81.46l0,0a61.14,61.14,0,0,0,34.73,83.85l.05,0c54.2,19.27,54.2,95.93,0,115.2l-.05,0A61.13,61.13,0,0,0,523,441.46l0,.05c24.69,52-29.51,106.16-81.46,81.46l0,0a61.14,61.14,0,0,0-83.85,34.72l0,.06c-19.28,54.2-95.92,54.2-115.2,0l0-.06A61.14,61.14,0,0,0,158.53,523l-.05,0c-51.95,24.7-106.15-29.5-81.45-81.46l0-.05a61.13,61.13,0,0,0-34.73-83.84l0,0c-54.2-19.27-54.2-95.93,0-115.2l0,0a61.13,61.13,0,0,0,34.73-83.84l0,0c-24.7-52,29.5-106.16,81.45-81.46l.05,0a61.14,61.14,0,0,0,83.85-34.72l0-.06C261.68-11.93,338.32-11.93,357.6,42.27Z"/>
      <path fill="#fff" d="M361.38,436.1c-18.34-16.22-29.58-36.42-32.89-60.63a180.43,180.43,0,0,1-.7-41.55c.84-8.8,6.15-15.14,14.36-18.47A35.94,35.94,0,0,0,357,304.73c4.62-5.64,11-8.36,18.25-8.49,10.14-.19,20.28-.19,30.42-.13,2,0,2.63-.43,2.63-2.55q-.1-59.62,0-119.25c0-9.42-7.45-14.75-14.81-14.73q-93.85.24-187.72.09c-8.81,0-14.49,5.69-14.49,14.53V425c0,8.1,2.58,11.92,10.11,15,.14.06.2.27.31.41H366.11C365,438.47,363,437.51,361.38,436.1ZM239.05,211.2H359.63c5.47,0,9.12,3.15,9.23,7.94s-3.61,8.43-9.07,8.43q-60.43,0-120.86,0c-5.38,0-8.92-3.42-8.89-8.28S233.64,211.2,239.05,211.2Zm65,174.67a11.27,11.27,0,0,1-3.24.36q-31,0-61.92,0c-5.3,0-8.78-3.19-8.83-7.95-.06-4.92,3.43-8.18,8.86-8.19,10.32,0,20.65,0,31,0,10.59,0,21.19,0,31.78,0a8,8,0,0,1,8,6.83A8.11,8.11,0,0,1,304,385.87Zm-2.64-32.55c-10.59.06-21.19,0-31.78,0h0c-10.6,0-21.19,0-31.79,0a8,8,0,0,1-7.6-6,8.09,8.09,0,0,1,8.09-10.16c21,0,42,0,63,0,4.93,0,8.4,3.5,8.35,8.16A8,8,0,0,1,301.39,353.32Zm-63.51-27.43a7.93,7.93,0,0,1-7.74-6.45,8.13,8.13,0,0,1,5.16-9.22,8.69,8.69,0,0,1,3.22-.44q31.38,0,62.75,0a8.11,8.11,0,0,1,8.36,6.74,8.21,8.21,0,0,1-5.49,9,10.84,10.84,0,0,1-3.79.38q-15.35,0-30.69,0v0C259.07,325.91,248.47,326,237.88,325.89Zm62.94-32.52q-30.93.08-61.86,0c-5.38,0-8.93-3.38-8.92-8.23s3.58-8.13,9-8.13h61.59c5.42,0,9,3.21,9.1,8S306.13,293.36,300.82,293.37Zm0-32.9q-30.93.07-61.86,0c-5.39,0-8.94-3.39-8.92-8.24s3.58-8.12,9-8.13h61.58c5.42,0,9,3.22,9.1,8S306.12,260.46,300.82,260.47Z"/>
      <path fill="#fff" d="M404.32,438.54c6-3.64,12.25-6.92,17.83-11.24,18.47-14.31,29.3-33,32-56.25,1.29-11.09.42-22.26.44-33.39a7.8,7.8,0,0,0-5.74-7.84,43.37,43.37,0,0,1-19.51-13.63,8.57,8.57,0,0,0-7-3.27c-9.76.14-19.52-.44-29.28.39-5.45.46-11,.15-16.44.09a9.43,9.43,0,0,0-8,3.8,42,42,0,0,1-17.43,12.89c-5.73,2.28-6.82,4-6.82,10.1,0,7.4-.17,14.8,0,22.19a82.28,82.28,0,0,0,20.25,52.88c8.38,9.8,19,16.4,30.07,22.51,1.19.65,2.84.9,3.15,2.65h4.38C402.28,439.06,403.53,439,404.32,438.54Zm-4.67-41.92a6.92,6.92,0,0,1-5.63,2.22,7.57,7.57,0,0,1-6.46-3c-4.68-5.72-9.39-11.42-14-17.22a8.13,8.13,0,1,1,12.69-10.18c2.24,2.65,4.5,5.29,6.54,8.09,1.21,1.64,1.84,1.82,3.34.19,7-7.68,14.21-15.22,21.35-22.81a8,8,0,0,1,8.15-2.61,7.84,7.84,0,0,1,6,6.09,7.37,7.37,0,0,1-1.82,7.1Q414.81,380.61,399.65,396.62Z"/>
    </SVG>
  )
}

export default Insurance
