import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Circle, Container, Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { RiArrowRightSLine } from "react-icons/ri";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from '@emotion/styled';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { responsive } from 'contexts/responsive';
import Item from '../Item';

export const properties = [
  {
    name: '土地財產', eng: 'possession', img: <StaticImage src="land.png"  alt="土地財產" />,
    detail: [
      {
        // title: '資料包含',
        desc: [
          '路段：土地所有權狀或土地登記謄本土地標示部上所登載的「路段說明」',
          '面積：土地所有權狀或土地登記謄本土地標示部上所登載的「面積」',
          '權力範圍：所擁有該筆資產的比例',
          '單位：取得價額（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '申報日前五年內取得：取得價額欄位為「實際交易價額（元）」',
          '無實際交易價額者：取得價額欄位為「取得年度之土地公告現值或市價（元）」',
          '申報日前取得五年以上：未規定必申報實際交易價額，取得價額欄位為「（超過五年）」',
        ]
      },
    ],
    head: '土地',
    estate: [
      {
        title: '土地取得價額 (單筆資料)',
        desc: [
          '申報日前五年內取得：中選會資料「實際交易價額（元）」',
          '無實際交易價額者：中選會資料「取得年度之土地公告現值或市價（元）」*「該筆土地路段公告現值（每平方公尺/元）」',
          '申報日前取得五年以上：未規定必申報實際交易價額，取得價額欄位為「（超過五年）」，本網站查詢該路段申報日的土地公告現值或市價（元）*「候選人該筆土地的持分面積（平方公尺）」',
        ]
      },
      {
        title: '土地總金額（所有資料）',
        desc: [
          '候選人及其配偶、其未成年子女，所有「土地取得價額」之總和',
        ]
      },
    ],
    area: [
      {
        title: '土地持有面積（單筆資料）',
        desc: [
          '面積（平方公尺）*權力範圍（持分）',
        ]
      },
      {
        title: '土地總面積（所有資料）',
        desc: [
          '候選人及其配偶、其未成年子女，所有「土地持有面積」之總和',
        ]
      },
    ]
  },
  {
    name: '建物財產', eng: 'building', img: <StaticImage src="building.png"  alt="建物財產" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '建物：包含房屋及停車位',
          '路段：房屋所有權狀或登記謄本上所登載的「路段說明」',
          '面積：房屋所有權狀或登記謄本上所登載的「面積」及「持分」；停車位具獨立之所有權狀者，權狀或登記謄本上所登載的「面積」及「持分」。',
          '權力範圍：所擁有該筆資產的比例',
          '單位：取得價額（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '申報日前五年內取得：實際交易價額或原始製造價額',
          '無實際交易價額者：取得年度之房屋課稅現值或市價',
          '原始製造價額者：取得年度之房屋課稅現值或市價',
          '建物及其坐落之土地，應分別填載於建物欄和土地欄',
        ]
      },
    ],
    head: '建物',
    estate: [
      {
        title: '建物（房屋及停車位）取得價額（單筆資料）',
        desc: [
          '申報日前五年內取得：中選會資料「實際交易價額或原始製造價額（元）」',
          '無實際交易價額者：中選會資料「取得年度之房屋課稅現值或市價（元）」*「該筆建物路段公告現值（每平方公尺/元）」',
          '原始製造價額者：中選會資料「取得年度之房屋課稅現值或市價（元）」，但若遇取得價額欄位為「（超過五年）」，本網站查詢該路段申報日的房屋課稅現值或市價（元）*「候選人該筆建物的持分面積（平方公尺）」',
        ]
      },
      {
        title: '建物總金額（所有資料）',
        desc: [
          '候選人及其配偶、其未成年子女，所有「建物取得價額」之總和',
        ]
      },
    ],
    area: [
      {
        title: '建物持有面積（單筆資料）',
        desc: [
          '面積（平方公尺）*權力範圍（持分）',
        ]
      },
      {
        title: '建物總面積（所有資料）',
        desc: [
          '候選人及其配偶、其未成年子女，所有「建物持有面積」之總和',
        ]
      },
    ]
  },
  {
    name: '現金存款', eng: 'cash', img: <StaticImage src="cash.png"  alt="現金存款" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '現金（新臺幣、外幣之現金或旅行支票）',
          '存款（新臺幣、外幣之存款）：包括支票存款、活期存款、定期存款、儲蓄存款、優惠存款、綜合存款、可轉讓定期存單等金融事業主管機關(構)核定之各種存款及由公司確定用 途之信託資金，包括新台幣、外幣(匯)存款在內。',
          '外幣(匯)、外幣現金或旅行支票，須折合新臺幣時，均以申報日之收盤匯率為計算標準。',
          '單位：新臺幣總額或折合新臺幣總額（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '現金：達新台幣一百萬元以上時，即應逐筆申報；若該項資產低於一百萬元時，候選人未申報，則該項資料為零。',
          '存款：申報人本人、配偶及未成年子女名下「各別」之存款總額累計達新台幣一百萬元者，即應由申報人逐筆申報；若該項資產低於一百萬元時，候選人未申報，則該項資料為零。',
        ]
      },
    ],
  },
  {
    name: '有價證券', eng: 'security', img: <StaticImage src="security.png"  alt="有價證券" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '包含上市(櫃)股票、興櫃股票、其他未上市(櫃)股票及下市(櫃)股票。',
          '股票名稱',
          '股數',
          '單位：股票股數*每股票面價額（10元/股），新臺幣總額或折合新臺幣總額（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '上市(櫃)股票、興櫃股票、其他未上市(櫃)股票及下市(櫃)股票，均應申報。',
        ]
      },
    ],
    // way: [
    //   '上市(櫃)股票：本網站查詢「候選人持有之上市(櫃)股票」*「申報日當天之新台幣收盤價（元）」',
    //   '興櫃股票：本網站查訊「候選人持有之興櫃股票股數」*「申報日當天之新台幣收盤價（元）」',
    //   '其他未上市(櫃)股票：股票股數*每股票面價額（10元/股）',
    //   '下市(櫃)股票：股票股數*每股票面價額（10元/股）',
    //   '單位：新臺幣總額或折合新臺幣總額（元）',
    // ]
  },
  {
    name: '保險', eng: 'insurance', img: <StaticImage src="insurance.png"  alt="保險" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '包含儲蓄型壽險、投資型壽險及年金型保險之保險契約類型。 ',
          '儲蓄型壽險：指滿期保險金、生存(還本)保險金、繳費期滿生存保險金、祝壽保險金、教育保險金、立業保險金、養老保險金等商品內容含有生存保險金特性之保險契約。',
          '投資型壽險：指商品名稱含有變額壽險、變額萬能壽險、投資型保險、投資連(鏈)結型保險等文字之保險契約。',
          '年金型保險：指即期年金保險、遞延年金保險、利率變動型年金保險、勞退企業年金保險、勞退個人年金保險等商品名稱含有年金保險等文字之保險契約。',
          '單位：累積已繳保險費折合新臺幣總金額（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '要保人迄申報日止已繳納之保險費。',
        ]
      },
    ],
  },
  {
    name: '事業投資', eng: 'business', img: <StaticImage src="investment.png"  alt="事業投資" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '指對於未發行股票或其他有價證券之各種公司、合夥、獨資等事業之投資，包括儲蓄互助社之社員股金。',
          '單位：新台幣（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '以「申報日」當日實際投資金額申報',
          '申報人本人、配偶及未成年子女「各別」名下事業投資達新臺幣一百萬元以上者；若該項資產低於一百萬元時，候選人未申報，則該項資料為零。 ',
        ]
      },
    ],
  },
  {
    name: '債權', eng: 'claims', img: <StaticImage src="claims.png"  alt="債權" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '民法規定的債權定義，申報人本人、配偶及未成年子女為債務人，與他人有契約、侵權行為、票據、無因管理、不當得利等關係。',
          '單位：新台幣（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '以「申報日」當日之債權餘額為準，須扣除債務人已清償部分，非以原始借貸數額申報。',
          '申報人本人、配偶及未成年子女「各別」名下債權達新臺幣一百萬元以上者；若該項資產低於一百萬元時，候選人未申報，則該項資料為零。 ',
        ]
      },
    ],
  },
  {
    name: '債務', eng: 'debt', img: <StaticImage src="debt.png"  alt="債務" />,
    detail: [
      {
        // title: '資料細項',
        desc: [
          '民法規定的債務定義，申報人本人、配偶及未成年子女為債權人，與他人有契約、侵權行為、票據、無因管理、不當得利等關係。',
          '單位：新台幣（元）',
        ]
      },
      {
        // title: '其他說明',
        desc: [
          '以「申報日」當日之債務餘額為準，須扣除已清償之部分，非以原始借貸數額申報。',
          '申報人本人、配偶及未成年子女「各別」名下債務達新臺幣一百萬元以上者；若該項指標低於一百萬元時，候選人未申報，則該項資料為零。 ',
        ]
      },
    ],
  },
]

const StyledSwiper = styled(Swiper)`
  // overflow: visible;
  .swiper-button-next {
    transform: translateX(190%);
    color: #002424;
  }
  .swiper-button-prev {
    transform: translateX(-192.5%);
    color: #002424;
  }
  .swiper-pagination {
    // bottom: 1rm;
  }
  .swiper-pagination-bullet {
    background-color: #FFF;
    opacity: 1;
    width: 5px;
    height: 5px;
  }
  .swiper-pagination-bullet-active {
    background-color: #A4A2A2;
  }
`

const Property = () => {
  const swipeRef = useRef()
  const [isOpen, onOpen] = useState()
  const [transit, setTransit] = useState()
  const [activeIndex, setIndex] = useState(0)
  useEffect(() => {
    if(!swipeRef.current) return null
  }, [swipeRef])
  return (
    <Box bg="bgGray" overflow={'hidden'}>
      <Container pt={responsive('1em', '3.375em')} pb={responsive('0', '7em')}>
        <Text fontSize={responsive('0.875em', '1.875em')} fontWeight={600} textAlign={'center'}>
          如果不知道財產項目定義，<br />
          可點擊下方小卡查看完整說明
        </Text>
        <Box position={'relative'} mt={responsive('1.25em', '4em')} p={responsive('0 1.25em',"0 2.5em")}>
          <StyledSwiper
            slidesPerView={3}
            spaceBetween={8}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              // when window width is >= 769px
              769: {
                pagination: false,
                spaceBetween: 42.5
              },
            }}
            ref={swipeRef}
            onSlideChange={(swiper) => {
              setTransit(true)
              setIndex(swiper.activeIndex)
              setTimeout(() => {
                setTransit(false)
              }, 300);
            }}
            style={{
              paddingBottom: "2.75em"
            }}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {properties.map((d, i) => (
              <SwiperSlide pos="relative" style={{ height: 'auto' }} key={i}>
                <Button
                  onClick={() => {
                    onOpen(i.toString())
                  }}
                  trackerName={`estate-${d.name}`}
                  p="0"
                  _hover={{}}
                >
                  <Flex
                    flexDir={'column'}
                    bg="bgPurple"
                    height="100%"
                    pt={responsive('0.5em', '2.25em')}
                    borderRadius="none"
                    fontFamily={'serif'}
                  >
                    <Box px={responsive('0.5em', '1.25em')}>
                      <Box
                        color={'white'}
                        width="1em"
                        height={'4.8em'}
                        lineHeight={1.2}
                        fontSize={responsive('0.75em', '1.875em')}
                        whiteSpace="pre-wrap"
                      >
                        {d.name}
                      </Box>
                        {/* <Box
                          mt={responsive("0.375em", '1em')}
                          p={responsive('0.375em', '2em 1.125em')}
                          bg="rgba(255,255,255,0.7)"
                          fontSize={responsive('5px', '0.875em')}
                          color="bgPurple"
                        >
                          ＊申報人（候選人）之配偶及未成年子女各別所有之財產，符合公職人員財產申報法所定應申報之標準者，應由申報人（候選人）一併申報。
                        </Box> */}
                      <Flex alignItems={'center'}>
                        <Divider />
                        <Circle color="bgPurple" bg="white" fontSize={responsive('1em', '2em')} size={responsive('0.75em', '1em')} ml={responsive("0.25rem", '0.875rem')}>
                          <Icon as={RiArrowRightSLine} w={responsive(2, 8)} h={responsive(2, 8)} />
                        </Circle>
                      </Flex>
                    </Box>
                    <Flex flex={1} alignItems="flex-end" mt={responsive('0.25em', '0.75em')}>
                      {d.img}
                    </Flex>
                  </Flex>
                </Button>
              </SwiperSlide>
            ))}
          </StyledSwiper>
          <Button
            pos={'absolute'}
            variant="transparent"
            top="50%"
            left="0"
            transform={`translate(-75%, -50%)`}
            disabled={activeIndex === 0 || transit}
            onClick={() => swipeRef?.current?.swiper.slidePrev()}
          >
            <Icon as={IoIosArrowBack} w={responsive(4, 12)} h={responsive(4, 12)} />
          </Button>
          <Button
            pos={'absolute'}
            variant="transparent"
            top="50%"
            right="0"
            transform={'translate(75%, -50%)'}
            disabled={activeIndex === (properties.length - 3) || transit}
            onClick={() => swipeRef?.current?.swiper.slideNext()}
          >
            <Icon as={IoIosArrowForward} w={responsive(4, 12)} h={responsive(4, 12)} />
          </Button>
        </Box>
      </Container>
      {isOpen && (
        <Item isOpen={isOpen} data={properties?.[isOpen]} onClose={() => onOpen(false)} />
      )}
    </Box>
  )
}

export default Property
