import React, { useMemo, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Flex,
  Text,
  Divider,
  Icon,
  HStack,
  useDisclosure,
  Tooltip,
  IconButton
} from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import { StaticImage } from 'gatsby-plugin-image';
import { IoCaretBack } from 'react-icons/io5';

import Button from 'components/Button';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import findIndex from 'lodash/findIndex';
import Building from './IconSvg/Building';
import Land from './IconSvg/Land';
import Debt from './IconSvg/Debt';
import Investment from './IconSvg/Investment';
import Claims from './IconSvg/Claims';
import Insurance from './IconSvg/Insurance';
import Security from './IconSvg/Security';
import Cash from './IconSvg/Cash';
import Medal from './IconSvg/Medal';
import ArrowDown from './ArrowDown';
import BarChart from './BarChart';
import MedalModal from './MedalModal';
import Possession from './Possession';

import building from './building.json';
import land from './land.json';
import candidateData from './data.json';

import { parties } from '.';

export const addComma = (num) => (+num)?.toLocaleString("en-US")

const transformData = (data) => data.reduce((res, d) => {
  if(res[d.name]) {
    res[d.name].push(d)
  } else {
    res[d.name] = [d]
  }
  return res
}, {})

const percentage = (num) => `${num * 100}%`

export const properties = [
  {
    name: '土地財產', key: 'land', note: '點開看更多資料', IconSvg: <Land />,
    sticker: <StaticImage src="./sticker/land.png" alt="land"  />,
    top: 350 / 540 , right: 237 / 1040, width: 148 / 1040
  },
  { name: '建物財產', key: 'building', note: '點開看更多資料', IconSvg: <Building />,
    sticker: <StaticImage src="./sticker/building.png" alt="building"  />,
    top: 282 / 540 , right: 87 / 1040, width: 150 / 1040
  },
  { name: '現金存款', key: 'savings', IconSvg: <Cash />,
    sticker: <StaticImage src="./sticker/cash.png" alt="savings" />,
    top: 101 / 540 , right: 143 / 1040, width: 112 / 1040
  },
  { name: '有價證券', key: 'security', IconSvg: <Security />,
    sticker: <StaticImage src="./sticker/security.png" alt="security"  />,
    top: 224 / 540 , right: 199 / 1040, width: 176 / 1040
  },
  { name: '保險金額', key: 'insurance', note: '累積已繳保險費', transit: '折合', IconSvg: <Insurance />,
    sticker: <StaticImage src="./sticker/insurance.png" alt="insurance" />,
    top: 92 / 540 , right: 403 / 1040, width: 104 / 1040
  },
  { name: '事業投資', key: 'business', IconSvg: <Investment />,
    sticker: <StaticImage src="./sticker/business.png" alt="business" />,
    top: 177 / 540 , right: 316 / 1040, width: 156 / 1040
  },
  { name: '債權金額', key: 'claims', IconSvg: <Claims />,
    sticker: <StaticImage src="./sticker/claims.png" alt="claims" />,
    top: 89 / 540 , right: 242 / 1040, width: 153 / 1040
  },
]

const estate = properties.slice(0, 2).map(d => d.key)

const ProportionBlock = ({ text, money, btnBg, width, debtColor }) => {
  return (
    <Flex alignItems={'center'} pt={responsive('1em', '2.5em')}>
      <Flex flexDir={responsive('column', 'row')} width={responsive('28%', '30%')} fontWeight={500} fontSize={responsive('0.75em', '1.5em')}>
        <Text>{text}</Text>
        <Text ml={responsive('0', '0.5rem')}>{addComma(money)} 元</Text>
      </Flex>
      <Box ml={responsive('0.625em', '1em')} flex={1}>
        <Box
          width={width}
          minWidth="4em"
          borderRadius="0 2em 2em 0"
          textAlign={'right'}
          bg={money && btnBg}
          py={responsive('0.25em', '0.875em')}
          pr={responsive('0.5em', '1em')}
          fontSize={responsive('0.75em', '1em')}
          color={debtColor}
        >
          {width}
        </Box>
      </Box>
    </Flex>
  )
}

const share = [
  {
    name: '分享此頁面',
    href: 'https://www.facebook.com/sharer/sharer.php?u=https://citycandidate.whatthedata.cc/'
  },
  {
    name: '中選會資料連結',
    href: 'https://111declaration.cec.gov.tw/'
  },
]

const topEstate = properties.concat([{
    name: '債務金額', key: 'debt', IconSvg: <Debt />, sticker: <StaticImage src="./sticker/debt.png" alt="debt" />,
    top: 270 / 540 , right: 355 / 1040, width: 141 / 1040
  }]).map((d) => {
  const topData = sortBy(candidateData.map(n => ({ ...n, [d.key]: +n[d.key] })), [d.key]).filter(g => Boolean(+g[d.key])).reverse().slice(0, round(candidateData.length * 0.1))
  return {
    ...d,
    topData
  }
})

const CandidatesModal = ({ isOpen, data, onClose }) => {
  const [medalOpen, setOpenModal] = useState()
  const partyData = useMemo(() => parties?.[data?.party], [data])
  const richer = useMemo(() => +data?.total > +data?.debt, [data])
  const noParty = useMemo(() => data?.party === '無黨籍', [data])
  const detailOfLand = transformData(land)
  const detailOfBuilding = transformData(building)
  const addNumPropertis = useMemo(() => properties.map((d) => ({
    ...d, total: round(data?.[d.key], 2),
    detail: estate.includes(d.key) && (Boolean(findIndex(estate, (o) => o === d.key)) ? detailOfBuilding[data?.name] : detailOfLand[data?.name])
  })) ,[data, estate, detailOfBuilding, detailOfLand])
  // console.log(topEstate)
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="noneBg" />
      <ModalContent bg="noneBg" boxShadow="none" width={responsive('100%')} mt="0" mb="3.75em" borderRadius={0} maxWidth="65em">
        <Flex justifyContent={'space-between'} mt={responsive('1.125rem', '5.75rem')} mx={responsive('1.5rem')}>
          <Button
            variant={'heavyGreen'}
            width={'auto'}
            iconSpacing={'2px'}
            leftIcon={<Icon as={IoCaretBack} w={responsive(4, 6)} h={responsive(4, 6)} />}
            fontSize={responsive('0.75em', '1.375em')}
            lineHeight={1}
            letterSpacing="0.1em"
            borderRadius={'full'}
            pl={responsive('0.375rem', '1.75rem')}
            pr={responsive('0.625rem', '2.25rem')}
            py={responsive('0.25rem', '0.75rem')}
            color="white"
            onClick={onClose}
            _hover={{}}
            _active={{}}
          >返回</Button>
          {/* <Box>
            <IconButton onClick={onOpen} variant="transparent" fontSize={responsive('1.5em', '2em')} icon={<IoMenu size="1em" />} />
          </Box> */}
        </Flex>
        <Box color="btnDpp" bg={partyData?.bg} mt={responsive("1em", '4.875em')}>
          <Box pos="relative" lineHeight={1}>
            {topEstate.filter(d => d.topData.some(o => o.name === data?.name)).length > 3 ? partyData?.cover : partyData?.fancyCover}
            {topEstate.map(({ topData, right, top, width, sticker }, i) => {
              const achieve = topData.some(d => d.name === data?.name)
              return achieve && (
                <Box pos="absolute" right={percentage(right)} top={percentage(top)} width={percentage(width)} key={i}>
                  {sticker}
                </Box>
              )}
            )}
          </Box>
          <Box px={responsive('2.75em', '8.375em')}>
            <Text textAlign={'center'} fontSize={responsive('17.5px', '45px')} fontWeight={700}>
              {data?.name}{data?.eng}
            </Text>
            <Box mt={responsive("0.625rem", '1.5rem')} py={responsive('1px', '0.5rem')} fontSize={responsive('0.75em', '1.625em')} borderRadius="full" bg={partyData?.posBg} textAlign="center">
              <span>{data?.city}・</span>
              <span>{data?.party}</span>
            </Box>
            <Divider mt={responsive("0.75em", '2.25em')} mb="0.25rem" />
            <Flex fontWeight={'bold'} justifyContent={'center'} alignItems="center">
              <Text mt="0.375rem" fontSize={responsive('0.875em', '1.875em')}>總財產</Text>
              <Text px={responsive('0.5rem', '1rem')} fontSize={responsive('1.875em', '5em')}>{addComma(data?.total)}</Text>
              <Text mt="0.375rem" fontSize={responsive('0.875em', '1.875em')}>元</Text>
            </Flex>
            <HStack spacing={responsive(2, 4)}>
              {topEstate.map(({ topData, IconSvg, name }, i, {length}) => {
                const achieve = topData.some(d => d.name === data?.name)
                return (
                  <Box onClick={() => setOpenModal(i.toString())} cursor={'pointer'} pointerEvents={!achieve && 'none'} width={1 / length} color={achieve ? partyData?.btnBg : partyData?.posBg} key={i}>
                    <Tooltip label={`${name}徽章`}>
                      {achieve ? IconSvg : <Medal />}
                    </Tooltip>
                  </Box>
                )}
              )}
            </HStack>
            <MedalModal isOpen={medalOpen} onClose={() => setOpenModal(false)} data={topEstate?.[medalOpen]} color={partyData?.btnBg} />
            {!!+data?.total ? (
              <BarChart properties={properties} data={data} noParty={noParty} partyData={partyData} />
            ) : (
              <Text textAlign={'center'} my="1em" fontSize={responsive('0.75em', '1.5em')}>因候選人資料不足，無法顯示各資產比重圖</Text>
            )}
            <Text fontSize={responsive('0.75em', '1.5em')} textAlign="center">點擊下方財產指標看詳細資料</Text>
            <Box textAlign={'center'} py={responsive('0.5em', '1em')}>
              <ArrowDown width={responsive('0.5em', '0.75em')} />
            </Box>
          </Box>
          <Possession addNumPropertis={addNumPropertis} partyData={partyData} />
          <Box px={responsive('1em', '3em')} pt={responsive('2em', '5.625em')}>
            {(!!+data?.total || !!+data?.debt) && (
              <>
                <Text fontWeight={600} fontSize={responsive('1em', '1.875em')}>
                  債務與資產的比例
                </Text>
                <ProportionBlock
                  text={'總財產'}
                  width={richer ? '100%' : `${round(+data?.total * 100/ +data?.debt, 2)}%`}
                  money={+data?.total}
                  btnBg={noParty ? 'deepGray' : 'white'}
                />
                <ProportionBlock
                  text={'總債務'}
                  width={!richer ? '100%' : `${round(+data?.debt * 100 / +data?.total, 2)}%`}
                  money={+data?.debt}
                  btnBg={partyData?.btnBg}
                  debtColor={!+data.debt ? 'black' : 'white'}
                />
              </>
            )}
            <HStack justifyContent={'center'} spacing={responsive(2, 10)} pt={responsive('3em', '5.5em')} pb={responsive('1.875em', '5em')}>
              {share.map(({ name, href }) => (
                <Button
                  width={responsive('7rem', '15rem')}
                  py={responsive('0.5rem', '0.875rem')}
                  fontSize={responsive('0.875rem', '1.125rem')}
                  bg={partyData?.btnBg}
                  color="white"
                  key={name}
                  href={href}
                >{name}</Button>
              ))}
            </HStack>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default CandidatesModal
