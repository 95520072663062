import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import Button from 'components/Button'
import { responsive } from 'contexts/responsive'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Footer = () => {
  return (
    <Flex alignItems={'center'} fontFamily="noto" flexDir="column" bg="paleGray" py={responsive('1.625em', '1.75em')} px="2em">
      <Button variant="transparent" href="https://relab.cc" width={responsive('3.375rem', '4.75rem')}>
        <StaticImage src="../relab.png" />
      </Button>
      <Flex py="0.625em">
        <Button fontWeight={400} href="https://www.facebook.com/sharer/sharer.php?u=https://citycandidate.whatthedata.cc/" variant={'transparent'}>分享此網站</Button>
        <Divider borderColor="black" height="auto" orientation="vertical" mx="0.5rem" />
        <Button fontWeight={400} href="https://whatthedata.cc/about/" variant={'transparent'}>聯繫團隊</Button>
      </Flex>
      <Text fontSize={responsive('0.75em', '0.875em')}>© copyrights RE:LAB</Text>
    </Flex>
  )
}

export default Footer
