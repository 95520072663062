import { Box } from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

const fluctuate = keyframes`
  0%, 50%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(20%);
  }
  75% {
    transform: translateY(-10%);
  }
`

const swing = keyframes`
  0%, 50%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15%);
  }
  75% {
    transform: translateX(-5%);
  }
`

const AnimationBox = styled(Box)`
  animation: 750ms ${props => props.swing ? swing : fluctuate} ease-out infinite;
`

const Animation = (props) => {
  // console.log(props)
  return (
    <AnimationBox {...props} />
  )
}

export default Animation
