import React, { useState, useMemo } from 'react'
import { Box, Container, Flex, HStack, Input, Select, Text } from '@chakra-ui/react'
import { BsFillCaretDownFill } from "react-icons/bs";
import { BiSearch } from 'react-icons/bi';
import { responsive } from 'contexts/responsive'
import { StaticImage } from 'gatsby-plugin-image';
import range from 'lodash/range';
import chunk from 'lodash/chunk';
import Button from 'components/Button';
import Filter from './Filter';

import CandidatesModal from './CandidatesModal';
import candidateData from './data.json';
import sortBy from 'lodash/sortBy';

const filters = [
  {
    name: '財產指標',
    options: [
      { name: '土地持有面積', key: 'land' },
      { name: '建物持有面積', key: 'building' },
      { name: '現金存款', key: 'savings' },
      { name: '有價證券', key: 'security' },
      { name: '保險金額', key: 'insurance' },
      { name: '事業投資', key: 'business' },
      { name: '債權金額', key: 'claims' },
      { name: '債務金額', key: 'debt' },
    ]
  },
  {
    name: '所在地區',
    options: [
      '新北市',
      '台北市',
      '桃園市',
      '台中市',
      '台南市',
      '高雄市',
      '基隆市',
      '宜蘭縣',
      '新竹縣',
      '新竹市',
      '苗栗縣',
      '彰化縣',
      '南投縣',
      '雲林縣',
      '嘉義縣',
      '屏東縣',
      '台東縣',
      '花蓮縣',
      '澎湖縣',
      '金門縣',
      '連江縣',
    ]
  },
  {
    name: '候選人黨籍',
    options: ['國民黨', '民進黨', '民眾黨', '時代力量', '無黨籍', '其他']
  },
]

export const parties = {
  其他: {
    img: <StaticImage alt="others" src="./parties/others.png" />, name: '其他黨', bg: 'others', cover: <StaticImage alt="cover_others" src="./cover/cover_others.png" />,
    fancyCover: <StaticImage alt="cover_others" src="./cover/cover_others_fancy.png" />,
    posBg: 'lightOthers', btnBg: 'btnOthers', estateBg: [
      '#D372A9', '#CB659F', '#DC7BB2', '#E88BBF', '#F19ACB', '#FAA7D6', '#FFB2DE', '#FFC6E6',
    ], chartBg: [
      '#712A43',
      '#A0506C',
      '#CC7E99',
      '#EBA7BF',
      '#F3C5D5',
    ]
  },
  無黨籍: {
    img: <StaticImage alt="noneParty" src="./parties/none.png" />, name: '無黨籍', bg: 'noneBg', cover: <StaticImage alt="cover_none" src="./cover/cover_none.png" />,
    fancyCover: <StaticImage alt="cover_none" src="./cover/cover_none_fancy.png" />,
    posBg: 'lightNoneBg', btnBg: 'btnNoneBg', estateBg: [
      '#BABABA',
      '#A1A1A1',
      '#C7C7C7',
      '#D4D4D4',
      '#E4E3E4',
      '#EAEAEA',
      '#F6F6F6',
      '#FFFFFF',
    ], chartBg: [
      '#030000',
      '#464646',
      '#6B6B6B',
      '#9D9D9D',
      '#C4C4C4',
    ]
  },
  國民黨: {
    img: <StaticImage alt="kmt" src="./parties/kmt.png" />, name: '國民黨', bg: 'kmt', cover: <StaticImage alt="cover_kmt" src="./cover/cover_kmt.png" />,
    fancyCover: <StaticImage alt="cover_kmt" src="./cover/cover_kmt_fancy.png" />,
    posBg: 'lightKmt', btnBg: 'btnKmt', estateBg: [
      '#5CACC8',
      '#4D9EBA',
      '#6EC0DD',
      '#80CBE5',
      '#8FD2EA',
      '#9DDBF1',
      '#B0E9FC',
      '#C9F1FF',
    ], chartBg: [
      '#205869',
      '#3B7689',
      '#5593A7',
      '#82B4C3',
      '#BADFED',
    ]
  },
  時代力量: {
    img: <StaticImage alt="npp" src="./parties/npp.png" />, name: '時代力量', bg: 'npp', cover: <StaticImage alt="cover_npp" src="./cover/cover_npp.png" />,
    fancyCover: <StaticImage alt="cover_npp" src="./cover/cover_npp_fancy.png" />,
    posBg: 'lightNpp', btnBg: 'btnNpp', estateBg: [
      '#DBC608',
      '#C6B309',
      '#E1D141',
      '#EBDC56',
      '#F1E46D',
      '#F8EB7D',
      '#FEF292',
      '#FFF9C6',
    ], chartBg: [
      '#DBC608',
      '#C6B309',
      '#E1D141',
      '#EBDC56',
      '#F1E46D',
    ]
  },
  民進黨: {
    img: <StaticImage alt="dpp" src="./parties/dpp.png" />, name: '民進黨', bg: 'dpp', cover: <StaticImage alt="cover_dpp" src="./cover/cover_dpp.png" />,
    fancyCover: <StaticImage alt="cover_dpp" src="./cover/cover_dpp_fancy.png" />,
    posBg: 'lightDpp', btnBg: 'btnDpp', estateBg: [
      '#75AD7C',
      '#6A9F70',
      '#8FC197',
      '#9DC8A3',
      '#AAD1B2',
      '#BCDAC2',
      '#CBE2D0',
      '#E8F2EA',
    ], chartBg: [
      '#0A2323',
      '#2C594E',
      '#427468',
      '#5D9486',
      '#81B7A9',
    ]
  },
  民眾黨: {
    img: <StaticImage alt="tmd" src="./parties/tmd.png" />, name: '民眾黨', bg: 'tmd', cover: <StaticImage alt="cover_tmd" src="./cover/cover_tmd.png" />,
    fancyCover: <StaticImage alt="cover_tmd" src="./cover/cover_tmd_fancy.png" />,
    posBg: 'lightTmd', btnBg: 'btnDpp', estateBg: [
      '#7FA8A8',
      '#6C8F8F',
      '#8BBABA',
      '#91C3C3',
      '#9CCECE',
      '#ABDFDF',
      '#BDEDED',
      '#D7FBFB',
    ], chartBg: [
      '#0A2323',
      '#185353',
      '#257878',
      '#379D9D',
      '#75CBCB',
    ]
  },
  親民黨: {
    img: <StaticImage alt="pfp" src="./parties/pfp.png" />, name: '親民黨', bg: 'pfp', cover: <StaticImage alt="cover_pfp" src="./cover/cover_pfp.png" />,
    fancyCover: <StaticImage alt="cover_pfp" src="./cover/cover_pfp_fancy.png" />,
    posBg: 'lightPfp', btnBg: 'btnPfp', estateBg: [
      '#E3716A',
      '#CA5C52',
      '#ED867F',
      '#F49E98',
      '#FCB2AD',
      '#FEC7C4',
      '#FFD8D6',
      '#FFEBEA',
    ], chartBg: [
      '#5A1C15',
      '#8A352B',
      '#B04C40',
      '#D46255',
      '#F48275',
    ]
  },

}

const Candidate = () => {
  const [filter, setFilter] = useState()
  const [search, setSearch] = useState()
  const [sort, setSort] = useState('0')
  const [open, setOpen] = useState()
  const [checkedItems, setCheckedItems] = useState(filters.map(d => range(d.options.length).fill(false)))
  const selections = useMemo(() => filters.map((d, i) => d.options.filter((info, k) => checkedItems[i][k])), [checkedItems])
  const candidateDataFilter = useMemo(() => candidateData.filter((d) => {
    if (search) {
      return d.name.includes(search)
    } else {
      return d
    }
  }).filter(d => {
    if (selections[1].length) {
      return selections[1].includes(d.city)
    } else {
      return d
    }
  }).filter(d => {
    if (selections[2].length) {
      return selections[2].includes(d.party)
    } else {
      return d
    }
  }) ,[search, selections])
  const sortData = useMemo(() => {
    if (selections[0].length) {
      if (+sort) {
        return sortBy(candidateDataFilter, (o) => +o[selections[0][0].key])
      } else {
        return sortBy(candidateDataFilter, (o) => +o[selections[0][0].key]).reverse()
      }
    } else {
      return candidateDataFilter
    }
  },[candidateDataFilter, selections, sort])
  return (
    <Box>
      <Container pt={responsive('2.25em', '4.5em')} pb={responsive('1.25em', '6.875em')} px={responsive('1.125em', '3.875em')}>
        <Text fontSize={responsive('0.875em', '1.875em')} textAlign="center" fontWeight={600}>來啦～候選人資料攏底加啦</Text>
        <Text mt="0.75rem" fontSize={responsive('0.75em', '1.125em')} textAlign="center" fontWeight={500}>偷偷告訴你，若資產指標有PR90以上即會有徽章，你找得到嗎？</Text>
        <Flex mt={responsive('2.75em', '3.275em')} justifyContent="space-between">
          <HStack>
            <Button trackerName="open-filter" height={responsive(6, 10)} rightIcon={<BsFillCaretDownFill />} onClick={() => setFilter(prev => !prev)}>篩選</Button>
            {selections[0].length && (
              <Box>
                <Select
                  onChange={(e) => {
                    if (typeof window.gtag === 'function') {
                      window.gtag("event", "click_button", {
                        button_name: e.target.value ? 'descent' : 'ascent',
                      });
                    }
                    setSort(e.target.value)
                  }}
                  icon={<BsFillCaretDownFill />}
                  borderRadius="full"
                  bg="black"
                  color="white"
                  fontFamily={'noto'}
                  fontSize={responsive('0.75em', '1.125em')}
                  fontWeight="500"
                  height={responsive(6, 10)}
                  defaultValue={sort}
                >
                  <Box as="option" color="black" value={0}>由多到少</Box>
                  <Box as="option" color="black" value={1}>由少到多</Box>
                </Select>
              </Box>
            )}
          </HStack>
          <Box width={responsive('40%', '25%')} position="relative">
            <Input
              pl={responsive('2em', '2.5em')}
              fontSize={'1em'}
              height={responsive(8, 10)}
              lineHeight={1}
              borderRadius={'full'}
              border="2px solid"
              borderColor="black"
              placeholder="搜尋人名"
              _hover={{}}
              onChange={(e) => setSearch(e.target.value)}
              onClick={() => {
                if (typeof window.gtag === 'function') {
                  window.gtag("event", "click_button", {
                    button_name: 'search',
                  });
                }
              }}
            />
            <Box position={'absolute'} fontSize={responsive('0.875em', '1.5em')} left="0.5em" top="50%" transform={'translateY(-50%)'}>
              <BiSearch size="1em" />
            </Box>
          </Box>
        </Flex>
        <Box pos="relative" mt="2.625em">
          <Box pos="relative" minHeight={responsive('50em', '56.25em')}>
            {sortData.length ? chunk(sortData, 5).map((data, k) => (
              <Flex display={'-webkit-box'} flexWrap={'nowrap'} pt={k && '2.75em'} key={k}>
                {data.map((d, i) => (
                  <Button
                    p="0"
                    color="black"
                    fontFamily="serif"
                    display="block"
                    bg="transparent"
                    fontWeight="400"
                    onClick={() => setOpen((i + (k * 5)).toString())}
                    width={responsive('33.65%', '32.55%')}
                    transform={i && `translateX(${-50 * i}%)`}
                    pos="relative"
                    filter={'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))'}
                    trackerName={d.name}
                    textAlign="left"
                    key={i}
                  >
                    {parties?.[d.party]?.img}
                    <Flex
                      lineHeight={1.2}
                      fontWeight={600}
                      pos="absolute"
                      top={responsive('0.5rem', '1rem')}
                      left={responsive('1rem', d.eng ? '6.5%' : '10%')}
                      fontSize={responsive('0.875em', '1.875em')}
                    >
                      <Text
                        whiteSpace="pre-wrap"
                        style={{
                          writingMode: "vertical-lr",
                          textOrientation: "upright"
                        }}
                      >{d?.name}</Text>
                      <Text
                        style={{
                          writingMode: "vertical-rl",
                          textOrientation: "mixed"
                        }}
                      >{d.eng}</Text>
                    </Flex>
                    {/* <Box lineHeight={1.1} fontWeight={600} pos="absolute" bottom={responsive('0.5rem', '1rem')} left={responsive('1rem', '10%')} fontSize={responsive('0.75em', '1.75em')} width={'1em'}>{d?.city}</Box> */}
                  </Button>
                ))}
              </Flex>
            )) : (
              <Text fontSize={responsive('0.875em', '1.875em')}>無符合之候選人</Text>
            )}
            <Text fontSize={responsive('0.875rem', '1.125rem')} pt={responsive('2rem', '3rem')}>
              「候選人財產」是由RE:LAB自主發起的實驗計畫。<br />
              RE:LAB 為資訊設計團隊，一直希望讓很重要的資料，能更容易閱讀與被使用，進而發揮更多價值。<br />
              於是著手規劃與設計，希望讓更多人一窺政府公開資料樣貌，並思考是否有更多、更好的做法或應用。<br />
              <br />
              感謝所有一起在幕後協助建議與整理資料的志工夥伴們，因為有你們的加入，讓這個計劃得以順利上線。<br />
              <br />
              如果你有任何想給我們的建議、或是單純想告訴我們做得很棒，都歡迎來信和我們聊聊或是訂閱與捐款支持我們的「台灣發生什麼數」計畫！
            </Text>
          </Box>
          {filter && (
            <Filter checkedItems={checkedItems} setCheckedItems={setCheckedItems} setFilter={setFilter} filters={filters} />
          )}
        </Box>
      </Container>
      <CandidatesModal data={sortData?.[open]} isOpen={open} onClose={() => setOpen(false)} />
    </Box>
  )
}

export default Candidate
