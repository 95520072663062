import SVG from 'components/SVG'
import React from 'react'

const Land = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.6,42.27l0,0A61.14,61.14,0,0,0,441.48,77h0c52-24.7,106.15,29.5,81.46,81.46l0,0a61.14,61.14,0,0,0,34.73,83.85l.05,0c54.2,19.27,54.2,95.93,0,115.2l-.05,0A61.13,61.13,0,0,0,523,441.46l0,.05c24.69,52-29.51,106.16-81.46,81.46l0,0a61.14,61.14,0,0,0-83.85,34.72l0,.06c-19.28,54.2-95.92,54.2-115.2,0l0-.06A61.14,61.14,0,0,0,158.53,523l-.05,0c-51.95,24.7-106.15-29.5-81.45-81.46l0-.05a61.13,61.13,0,0,0-34.73-83.84l0,0c-54.2-19.27-54.2-95.93,0-115.2l0,0a61.13,61.13,0,0,0,34.73-83.84l0,0c-24.7-52,29.5-106.16,81.45-81.46l.05,0a61.14,61.14,0,0,0,83.85-34.72l0-.06C261.68-11.93,338.32-11.93,357.6,42.27Z"/>
      <path fill="#f5f5f5" d="M431.94,402c0-.21.3-.37.46-.56v-7.8c-1,.06-1.06-.77-1.21-1.38-2.79-10.77-10.53-16.65-20.42-20a47.54,47.54,0,0,0-32.83.7,7.71,7.71,0,0,1-6,0,48.66,48.66,0,0,0-30.35-1.55,27.74,27.74,0,0,0-7,2.73,5.37,5.37,0,0,1-5.4.17c-7.9-3.63-16.29-5-24.93-5.53A83.86,83.86,0,0,0,275.56,372a44.57,44.57,0,0,0-6.54,2.51,4.73,4.73,0,0,1-4.46,0c-12.47-6-25.19-6.14-38.06-1.44a7.77,7.77,0,0,1-5.75,0c-12.59-4.61-25.16-4.64-37.39,1.05-8.09,3.77-14.19,9.57-16.07,18.81-2.16,10.61,3.68,21.6,14.13,26.65a36.17,36.17,0,0,0,8.84,2.94c.75.14,1.85.05,1.9,1.31h2.08c.9-1,2.06-.34,3.1-.49H401.21c.87.11,1.87-.41,2.59.49h2.08c2.94-1.07,6.11-1.25,9-2.48C423.78,417.67,429.8,411.6,431.94,402Z"/>
      <path fill="#f5f5f5" d="M190.26,422.53a36.17,36.17,0,0,1-8.84-2.94c-10.45-5-16.29-16-14.13-26.65,1.88-9.24,8-15,16.07-18.81,12.23-5.69,24.8-5.66,37.39-1.05a7.77,7.77,0,0,0,5.75,0c12.87-4.7,25.59-4.59,38.06,1.44a4.73,4.73,0,0,0,4.46,0,44.57,44.57,0,0,1,6.54-2.51,83.86,83.86,0,0,1,28.72-3.14c8.64.5,17,1.9,24.93,5.53a5.37,5.37,0,0,0,5.4-.17,27.74,27.74,0,0,1,7-2.73A48.66,48.66,0,0,1,372,373a7.71,7.71,0,0,0,6,0,47.54,47.54,0,0,1,32.83-.7c9.89,3.33,17.63,9.21,20.42,20,.15.61.21,1.44,1.21,1.38-1.79-7.41-5.62-13.51-12.17-17.53-13.87-8.52-28.44-9.12-43.46-3.39a3.71,3.71,0,0,1-3.14.24c-13.68-5.64-27.24-5.37-40.58,1.15a2.44,2.44,0,0,1-2.38.16c-8.37-4.08-17.3-5.75-26.52-6.21-12.5-.63-24.66.77-36.14,6.14a2.67,2.67,0,0,1-2.6-.15c-13.27-6.43-26.74-6.72-40.35-1.15a3.27,3.27,0,0,1-2.91,0,43.82,43.82,0,0,0-18.89-3.77c-10.69.06-20.57,2.6-28.69,9.93-6.69,6-9.74,13.6-8,22.61,2,10.29,8.65,16.6,18.25,20.06,2.38.86,4.88,1.4,7.32,2.08C192.11,422.58,191,422.67,190.26,422.53Z"/>
      <path fill="#f5f5f5" d="M400.55,423.57H198c-.21,0-.42,0-.63,0,0,.09,0,.18,0,.27H401.2a2.44,2.44,0,0,1,0-.27A4.85,4.85,0,0,1,400.55,423.57Z"/>
      <path fill="#f5f5f5" d="M198,423.58H400.55a4.85,4.85,0,0,0,.66,0v-.22H197.34a1.55,1.55,0,0,1,0,.22C197.56,423.57,197.77,423.6,198,423.58Z"/>
      <path fill="#f5f5f5" d="M194.24,423.84h3.12a.25.25,0,0,1-.14-.28A6.43,6.43,0,0,0,194.24,423.84Z"/>
      <path fill="#f5f5f5" d="M197.22,423.56l0,0c.05-.11.1-.17.15-.17-1,.15-2.2-.49-3.1.49A6.43,6.43,0,0,1,197.22,423.56Z"/>
      <path fill="#f5f5f5" d="M197.22,423.56a.25.25,0,0,0,.14.28c0-.09,0-.18,0-.27Z"/>
      <path fill="#f5f5f5" d="M197.22,423.56h.13a1.55,1.55,0,0,0,0-.22c-.05,0-.1.06-.15.17Z"/>
      <path fill="#f5f5f5" d="M401.3,423.56s.07.09.05.12-.1.16-.15.16h2.6A4.77,4.77,0,0,0,401.3,423.56Z"/>
      <path fill="#f5f5f5" d="M401.21,423.35h0c.1.07.08.14.09.21a4.77,4.77,0,0,1,2.5.28C403.08,422.94,402.08,423.46,401.21,423.35Z"/>
      <path fill="#f5f5f5" d="M401.3,423.56h-.09a2.44,2.44,0,0,0,0,.27c.05,0,.1,0,.15-.16S401.31,423.6,401.3,423.56Z"/>
      <path fill="#f5f5f5" d="M401.3,423.56c0-.07,0-.14-.09-.21h0v.22Z"/>
      <path fill="#f5f5f5" d="M223.16,338.1c1.82.43,2.43,1.09,2.37,3-.16,5-.05,10-.05,15.19a65.41,65.41,0,0,1,14-2.54c1.54-.1,1.8-.7,1.76-2.05-.09-3.38.12-6.77-.08-10.13-.14-2.19.53-3.05,2.72-3.54,15.12-3.45,25.5-12.52,31.07-26.95,4.28-11.1,2.44-22.73,2.35-34.17,0-3-3.4-5.7-6.41-5.88a41.82,41.82,0,0,0-8.29.22c-12.09,1.75-21.65,7.68-29.07,17.42-.24-.11-.35-.12-.4-.18-.28-.32-.56-.66-.83-1-8.93-11-20.41-16.69-34.69-16.54-5.27.06-8.48,3.24-8.51,8.59,0,4.33,0,8.67,0,13,0,4.77.05,9.52,1.36,14.14C195.2,323.55,206.08,334.09,223.16,338.1Z"/>
      <path fill="#f5f5f5" d="M402.22,271A41.87,41.87,0,0,0,383,274.44a43.62,43.62,0,0,0-17.84,14.41c-9.91-12.36-21-17.9-35.48-17.88-5.65,0-8.67,2.93-8.72,8.55-.05,5.28,0,10.57,0,15.85a40.21,40.21,0,0,0,1.33,11.82c4.94,16.39,15.6,26.89,32.39,30.85,1.94.46,2.89,1,2.72,3.29-.26,3.53-.07,7.1-.07,10.65,0,.89-.11,1.62,1.24,1.72a69.09,69.09,0,0,1,14.49,2.57c0-5,.17-9.85-.07-14.67-.12-2.39.68-3.08,2.91-3.62,16.34-3.9,27-14.09,31.88-30.06,3-9.84,1.6-20.11,1.49-30.2C409.26,274,405.75,271.16,402.22,271Z"/>
      <path fill="#f5f5f5" d="M323,212.08a22,22,0,0,1,6.66-.88,58.19,58.19,0,0,1,33.61,10.36c1.4,1,2.33,1.18,3.8.07a45.69,45.69,0,0,1,11.56-6.25c9.1-3.43,18.45-5.25,28.17-3.47,2,.36,2.48.29,2.48-1.85,0-13.34.08-26.67.13-40a9,9,0,0,0-1.75-5.84,7.83,7.83,0,0,0-8.37-2.62c-3.55,1.08-5.57,3.68-5.71,7.74-.12,3.8-.18,7.63,0,11.43.11,2-.65,2.54-2.34,2.79a34.74,34.74,0,0,0-10.93,3.84c-2.45,1.3-4.74,2.92-7.24,4.48,0-8.81.05-17.39,0-26-.06-6.27-5.82-10-11.3-7.48-3.41,1.6-4.45,4.54-4.43,8.1.05,8.37,0,16.73,0,25.37a7.73,7.73,0,0,1-1.12-.55,41,41,0,0,0-17.75-8c-1.57-.3-1.83-.93-1.8-2.34.09-4,.08-8,0-11.95a7.79,7.79,0,0,0-15.58-.07c-.06,4.16,0,8.32-.05,12.47,0,9.7,0,19.4,0,29.1C321.06,212.1,321.47,212.55,323,212.08Z"/>
      <path fill="#f5f5f5" d="M191.4,212a21.94,21.94,0,0,1,6.41-.81c12.25.1,23.54,3.32,33.63,10.36,1.38,1,2.27,1.16,3.79.06a52.78,52.78,0,0,1,16.47-7.91c8.41-2.38,16.89-3.49,25.72-1.34,0-14.83.07-29.47,0-44.1,0-4.07-3.65-7.05-7.86-7a7.55,7.55,0,0,0-7.65,7.27,99.55,99.55,0,0,0,0,10.65c.22,2.88-.39,4.49-3.61,4.57a14.92,14.92,0,0,0-4,1.13,47.76,47.76,0,0,0-13.13,7.19v-2.94c0-7.71.06-15.43,0-23.14-.06-5.12-3.29-8.41-7.91-8.35s-7.77,3.49-7.79,8.54c0,7.8,0,15.6,0,23.4v2.54a43.07,43.07,0,0,0-19-8.83c-1.49-.28-1.49-1-1.49-2.09,0-3.82.06-7.63,0-11.44-.06-3.55-1.37-6.44-4.83-7.89-5.45-2.3-10.87,1.49-11,7.75-.11,5.28,0,10.57,0,15.85,0,8.32.15,16.64.15,25C189.31,212.14,189.79,212.49,191.4,212Z"/>
      <path fill="#f5f5f5" d="M329.28,255.36a58.67,58.67,0,0,1,34.1,10.44c1.36.95,2.22,1.06,3.57.06A46.25,46.25,0,0,1,378,259.8c9.57-3.67,19.35-5.75,29.61-3.47,1.93.43,1.76-.79,1.77-1.87,0-6.67.13-13.34,0-20a7.77,7.77,0,0,0-7.33-7.67,42.2,42.2,0,0,0-28.87,9.42,49.5,49.5,0,0,0-8,8.45c-.38-.43-.62-.68-.83-.95-9.06-11.49-20.87-17.2-35.53-16.93-4,.07-7.5,2.79-7.68,6.59-.38,7.67-.27,15.36,0,23.16A47.17,47.17,0,0,1,329.28,255.36Z"/>
      <path fill="#f5f5f5" d="M191.28,256.21a23.71,23.71,0,0,1,6.92-.85c12.13.18,23.32,3.42,33.3,10.43,1.45,1,2.32.94,3.79,0,12.4-8.15,25.81-12.18,40.75-9.39.92.17,1.42.11,1.42-1.05,0-7.27.13-14.54,0-21.81a7.26,7.26,0,0,0-6.79-6.68,40.29,40.29,0,0,0-10.84.73c-10.86,2.32-19.52,8-26.39,17.15l-1.25-1.57c-9-11.08-20.7-16.61-35-16.36-4.28.08-7.82,3.06-7.94,7.29-.19,6.92,0,13.85,0,20.78C189.14,256.5,189.9,256.61,191.28,256.21Z"/>
    </SVG>
  )
}

export default Land
