import SVG from 'components/SVG'
import React from 'react'

const Debt = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.6,42.27l0,0A61.14,61.14,0,0,0,441.48,77h0c52-24.7,106.15,29.5,81.46,81.46l0,0a61.14,61.14,0,0,0,34.73,83.85l.05,0c54.2,19.27,54.2,95.93,0,115.2l-.05,0A61.13,61.13,0,0,0,523,441.46l0,.05c24.69,52-29.51,106.16-81.46,81.46l0,0a61.14,61.14,0,0,0-83.85,34.72l0,.06c-19.28,54.2-95.92,54.2-115.2,0l0-.06A61.14,61.14,0,0,0,158.53,523l-.05,0c-51.95,24.7-106.15-29.5-81.45-81.46l0-.05a61.13,61.13,0,0,0-34.73-83.84l0,0c-54.2-19.27-54.2-95.93,0-115.2l0,0a61.13,61.13,0,0,0,34.73-83.84l0,0c-24.7-52,29.5-106.16,81.45-81.46l.05,0a61.14,61.14,0,0,0,83.85-34.72l0-.06C261.68-11.93,338.32-11.93,357.6,42.27Z"/>
      <path fill="#fff" d="M151.1,184.07c2.83,6,5.45,12,8.5,17.89,1.44,2.74,3.44,4.77,6.77,5.07a8.14,8.14,0,0,0,8.29-11.25c-3.08-7.26-6.52-14.36-10-21.45-3.08-6.33-10.65-6.69-14.63-.92-.46.66-.59,1.77-1.81,1.63v5.33C150.36,180.71,150.43,182.68,151.1,184.07Z"/>
      <path fill="#fff" d="M159.05,202.76a8.82,8.82,0,0,0,11.7,4.4,8.66,8.66,0,0,0,4.7-11.3c-3.33-7.64-6.89-15.17-10.55-22.65a8.22,8.22,0,0,0-8-4.61,8.67,8.67,0,0,0-7.65,4.73c-.32.57-.22,1.41-1,1.71,1.22.14,1.35-1,1.81-1.63,4-5.77,11.55-5.41,14.63.92,3.44,7.09,6.88,14.19,10,21.45A8.14,8.14,0,0,1,166.37,207c-3.33-.3-5.33-2.33-6.77-5.07-3-5.86-5.67-11.93-8.5-17.89-.67-1.39-.74-3.36-2.84-3.7.83.35.87,1.23,1.19,1.9C152.65,189.09,155.75,196,159.05,202.76Z"/>
      <path fill="#fff" d="M436.59,349.36c-3.59-16-3.85-32.42-5.88-48.62-1.26-10.15-1.61-20.42-3.52-30.5-8.8-46.39-57.41-75.49-102.63-61.68-30.08,9.18-55.32,35.9-57.88,73.94-.16,2.45.6,3.77,2.51,5.3,27.39,21.89,38.72,50.19,32.5,84.81-4.26,23.73-17.26,42.08-37.47,55.77,7.74,5,15.83,7.72,24.7,7.74,39.17.06,78.33.24,117.5-.07,17.36-.14,30.93-8.16,39.51-23.29,8.17-14.41,7.52-29.23-.66-43.54C441.64,362.88,438.2,356.53,436.59,349.36ZM357.3,372.13c-2.4.74-3.13,1.76-2.85,4.06a22.8,22.8,0,0,1,0,4.13,6.92,6.92,0,0,1-7.09,6.79,7,7,0,0,1-7.32-6.89c-.15-2.14.9-4.88-.5-6.26-1.21-1.17-3.89-.76-5.88-1.22a42.45,42.45,0,0,1-14.88-6.87,7.27,7.27,0,0,1,7.87-12.21c3.34,1.87,6.55,4,10.36,4.84,2,.47,3.2.3,3.08-2.43-.24-5.41-.07-10.84-.07-16.26s-.08-10.85,0-16.27a3.09,3.09,0,0,0-2.07-3.37c-2.4-1.09-4.71-2.41-7-3.7-15.47-8.66-15.47-26.77-7.21-38.32a24.94,24.94,0,0,1,13.95-9.85c2-.55,2.5-1.44,2.33-3.26a28,28,0,0,1,0-3.55c.17-4.38,3.07-7.24,7.28-7.22s6.94,2.83,7.16,7c.27,5.13.29,5.09,5.35,6.68a26.81,26.81,0,0,1,10.89,6.26c3.38,3.31,3.67,7.69.76,10.78s-6.89,3.14-10.52.13c-1.75-1.46-3.91-3.63-5.8-2.87-1.65.67-.59,3.72-.61,5.71-.1,6.8.06,13.61-.1,20.41-.05,2.24.62,3.33,2.77,4,6.72,2,12.69,5.32,17.05,11C386.88,339.89,377.94,365.77,357.3,372.13Z"/>
      <path fill="#fff" d="M285.75,356.29A68.25,68.25,0,0,0,218.64,288C182,287.54,149.72,317,149.5,355.8c-.22,37.58,30.21,68.38,67.46,68.5C255.2,424.42,285.65,394.31,285.75,356.29Z"/>
      <path fill="#fff" d="M314.44,191.17c.48,1.81,1.21,1.84,2.69,1.35a97,97,0,0,1,61.42,0c1.56.5,2.19.41,2.67-1.36,1.39-5.2,3.08-10.32,4.57-15.5,1.88-6.57-2.13-11.83-9-11.85h-29c-9.86,0-19.72,0-29.58,0a8.75,8.75,0,0,0-8.54,11.32C311.18,180.5,313,185.79,314.44,191.17Z"/>
      <path fill="#fff" d="M160.28,289.67c.67,1.87,1.46,1.63,2.69.54a71,71,0,0,1,11.33-8,86.24,86.24,0,0,1,36.19-11.51c-1.69-3.48-3-6.88-4.9-9.94a11.06,11.06,0,0,0-9.66-5.31,14.83,14.83,0,0,0-7.55,2.17c-1.74.94-2.13.43-2.27-1.35a38.09,38.09,0,0,1,3.74-20.62c2.5-4.92.87-10.1-3.62-12.42s-9.67-.64-12.24,4.25a51.91,51.91,0,0,0-5.4,33.18c.9,5.58,1,5.57-4.27,8.13-5.47,2.66-8.09,7.44-7.12,13.25C157.66,284.8,159.36,287.09,160.28,289.67Z"/>
      <path fill="#fff" d="M196.84,206.35a8.66,8.66,0,0,0,5.95-5.69c2.65-7.28,5.42-14.51,8-21.83,2.12-6.14-1.9-12-8.08-12.11a8.53,8.53,0,0,0-8.55,5.57c-2.85,7.52-5.63,15.06-8.23,22.67a8.91,8.91,0,0,0,11,11.39Z"/>
      <path fill="#fff" d="M206.2,219.72A8.73,8.73,0,0,0,218,224.29c7.33-3.25,14.58-6.7,21.82-10.17a8.37,8.37,0,0,0,4.93-7c.14-7.38-6.37-11.8-12.52-9.1-7.26,3.18-14.42,6.58-21.56,10A8.72,8.72,0,0,0,206.2,219.72Z"/>
      <path fill="#fff" d="M356.65,327.77c-1.53-.67-2.23-.81-2.21,1.29.08,9.34,0,18.69,0,28.4a15,15,0,0,0,6.83-5.08C368.38,343.61,366.13,331.91,356.65,327.77Z"/>
      <path fill="#fff" d="M333,295.44c.06,4.39,2.11,7.08,6.85,8.85V283.53C335.55,285.23,332.93,289.86,333,295.44Z"/>
    </SVG>
  )
}

export default Debt
