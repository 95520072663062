import React from 'react'
import { Box, Circle, Flex, Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import round from 'lodash/round'
import { properties } from './CandidatesModal'

const BarChart = ({ data, noParty, partyData }) => {
  return (
    <Box my={responsive('5em', '10.75em')} position={'relative'} py={responsive('1em', '2.25em')} bg={noParty ? 'deepGray' : 'white'} borderRadius={'full'}>
      <Flex position={'absolute'} top="6px" left={'6px'} right="6px" bottom="6px">
        {properties.slice(2, properties.length).filter(({key}) => +data?.[key]).map((d, i, { length }) => {
          const percent = `${round(data?.[d.key] / data?.total * 100, 2)}%`
          const last = i === length - 1
          return percent !== '0%' && (
          <Box
            pos="relative"
            width={percent}
            minW={responsive('6%', '5%')}
            bg={partyData?.chartBg[i]}
            borderRadius={i ? last ? '0 3em 3em 0' : '0' : length === 1 ? 'full' : '3em 0 0 3em'}
            zIndex={length - i}
            color={partyData?.btnBg}
            key={i}
          >
            <Box
              width={'4em'}
              fontSize={responsive('0.75em', '1.875em')}
              position={'absolute'}
              left={responsive(!(i % 2) && ((i / 2) === 1) && !last ? '2.5rem' : '1rem', !(i % 2) && ((i / 2) === 1) && !last ? '4rem' : '2.5rem')}
              bottom={i % 2 ? '94%' : 'unset'}
              top={i % 2 ? 'unset' : '94%'}
              lineHeight={1.2}
            >
              <Box
                pos={'absolute'}
                width="2px"
                top={i % 2 ? '30%' : '0'}
                bottom={i % 2 ? '0' : '30%'}
                left={responsive('-0.5rem', '-1rem')}
                bg={noParty ? 'lineColor' : 'white'}
              >
                <Circle
                  size="0.5em"
                  fontSize={responsive('0.875rem', '1rem')}
                  bg={noParty ? 'lineColor' : 'white'}
                  position={'absolute'}
                  bottom={i % 2 ? '100%' : 'unset'}
                  top={i % 2 ? 'unset' : '100%'}
                  left="50%"
                  transform={'translateX(-50%)'}
                />
              </Box>
              <Box transform={(i % 2) && (i / 2) < 1 && 'translateX(-5em)'}>
                <Text mt={!(i % 2) && "1rem"} fontWeight={700}>{percent}</Text>
                <Text mb={(i % 2) && "1.25rem"} fontWeight={500} fontFamily="noto">{d.name}</Text>
              </Box>
            </Box>
            {!last && (
              <Box
                pos="absolute"
                width={'100%'}
                top="0"
                bottom="0"
                bg={partyData?.chartBg[i]}
                borderRadius={'0 3em 3em 0'}
                transform={responsive('translateX(0.5em)', 'translateX(1.5em)')}
              />
            )}
          </Box>
        )})}
      </Flex>
    </Box>
  )
}

export default BarChart
