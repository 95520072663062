import SVG from 'components/SVG'
import React from 'react'

const Investment = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.6,42.27l0,0A61.14,61.14,0,0,0,441.48,77h0c52-24.7,106.15,29.5,81.46,81.46l0,0a61.14,61.14,0,0,0,34.73,83.85l.05,0c54.2,19.27,54.2,95.93,0,115.2l-.05,0A61.13,61.13,0,0,0,523,441.46l0,.05c24.69,52-29.51,106.16-81.46,81.46l0,0a61.14,61.14,0,0,0-83.85,34.72l0,.06c-19.28,54.2-95.92,54.2-115.2,0l0-.06A61.14,61.14,0,0,0,158.53,523l-.05,0c-51.95,24.7-106.15-29.5-81.45-81.46l0-.05a61.13,61.13,0,0,0-34.73-83.84l0,0c-54.2-19.27-54.2-95.93,0-115.2l0,0a61.13,61.13,0,0,0,34.73-83.84l0,0c-24.7-52,29.5-106.16,81.45-81.46l.05,0a61.14,61.14,0,0,0,83.85-34.72l0-.06C261.68-11.93,338.32-11.93,357.6,42.27Z"/>
      <path fill="#fff" d="M146.75,385.36h310.9a51.12,51.12,0,0,0,5.25-.12,3.87,3.87,0,0,0,3.85-3.75c.19-2.19-1.22-3.4-3.13-4.08a12.5,12.5,0,0,0-4.22-.45h-20c-7.66,0-7.75-.1-7.75-7.62v-127a41.8,41.8,0,0,0-.16-5.25c-.53-4.26-2.11-5.8-6.4-5.84-10.4-.1-20.79,0-31.19,0a1.82,1.82,0,0,0-.33,0c-3.33.21-5,1.63-5.57,4.89a31.4,31.4,0,0,0-.22,5.57q0,63.86,0,127.71c0,7.52-.08,7.6-7.75,7.61-4.26,0-8.53.05-12.8,0s-5.12-.81-5.16-5.25c-.08-9.52,0-19,0-28.56V288.3c0-1.31,0-2.63-.05-3.94-.32-4.4-1.75-5.85-6.17-5.89q-15.27-.11-30.53,0c-5,0-6.41,1.39-6.44,6.5-.07,14.55,0,29.11,0,43.66q0,21,0,42c0,5.82-.5,6.28-6.42,6.3-4.81,0-9.63,0-14.44,0s-5.5-.74-5.51-5.57c-.05-17.95,0-35.89,0-53.84,0-9.29-.25-9.53-9.63-9.53H256.17c-5.77,0-7,1.15-7,6.9-.05,14.55,0,29.11,0,43.66,0,3.94,0,7.88,0,11.82,0,6.09-.49,6.57-6.51,6.57-29.87,0-59.75-.05-89.62.05-7.1,0-14.4-1.28-21.16,2.19v3.94C136.51,386.08,141.69,385.36,146.75,385.36Z"/>
      <path fill="#fff" d="M140,386.77q160.07-.06,320.12,0a18.25,18.25,0,0,0,3.92-.25,4.84,4.84,0,0,0,4.12-5.43c-.13-3.15-2.49-5.05-6.41-5.06-8.54,0-17.08-.2-25.61.08-3.31.11-3.8-.95-3.8-4q.16-66.66.07-133.3a31,31,0,0,0-.09-3.61c-.39-3.35-2.26-5.05-5.67-5.07-11.38,0-22.77,0-34.15,0-3.13,0-4.83,1.75-5.43,4.64a18.74,18.74,0,0,0-.15,3.6q0,67.31.05,134.61c0,2.38-.45,3.18-3,3.08-5.79-.21-11.6-.21-17.39,0-2.73.1-3.64-.42-3.62-3.46.14-29.44.09-58.88.08-88.32,0-5.07-1.82-6.86-6.9-6.86H325.23c-6.31,0-7.58,1.28-7.58,7.71q0,44,.05,88c0,2.25-.47,3-2.84,2.93-6.12-.17-12.26-.17-18.38,0-2.39.07-3.1-.45-3.09-3,.13-19.81.08-39.62.07-59.43,0-5.25-1.58-6.86-6.81-6.88q-15.76,0-31.52,0c-5.3,0-7,1.75-7,7.14,0,19.59-.06,39.18.06,58.77,0,2.66-.51,3.42-3.32,3.41-35-.11-70,0-105.06-.13-3.26,0-5.94.63-8,3.27,6.76-3.47,14.06-2.16,21.16-2.19,29.87-.1,59.75,0,89.62-.05,6,0,6.48-.48,6.51-6.57,0-3.94,0-7.88,0-11.82,0-14.55,0-29.11,0-43.66,0-5.75,1.21-6.88,7-6.9h26.59c9.38,0,9.63.24,9.63,9.53,0,18,0,35.89,0,53.84,0,4.83.74,5.52,5.51,5.57s9.63,0,14.44,0c5.92,0,6.41-.48,6.42-6.3q0-21,0-42c0-14.55-.05-29.11,0-43.66,0-5.11,1.4-6.47,6.44-6.5q15.25-.09,30.53,0c4.42,0,5.85,1.49,6.17,5.89.09,1.31.05,2.63.05,3.94v54.83c0,9.52-.05,19,0,28.56,0,4.44.79,5.17,5.16,5.25s8.54,0,12.8,0c7.67,0,7.75-.09,7.75-7.61q0-63.86,0-127.71a31.4,31.4,0,0,1,.22-5.57c.59-3.26,2.24-4.68,5.57-4.89a1.82,1.82,0,0,1,.33,0c10.4,0,20.79-.08,31.19,0,4.29,0,5.87,1.58,6.4,5.84a41.8,41.8,0,0,1,.16,5.25v127c0,7.52.09,7.61,7.75,7.62h20a12.5,12.5,0,0,1,4.22.45c1.91.68,3.32,1.89,3.13,4.08a3.87,3.87,0,0,1-3.85,3.75,51.12,51.12,0,0,1-5.25.12H146.75c-5.06,0-10.24.72-14.87-2.23C133.61,386.49,136.61,386.77,140,386.77Z"/>
      <path fill="#fff" d="M133.8,318.5c4,15.47,13,26.95,27.47,33.66,30.83,14.3,65-5.75,69.7-37.51,3.4-22.88-10.32-45.41-31.72-53.57-17.82-6.79-39.12-2-52.56,12a49.47,49.47,0,0,0-13,23.91c-.27,1.21.13,3-1.85,3.35v14.45C134,315.25,133.47,317.21,133.8,318.5Zm54.67-1.42c-.48-2.2-2-3-3.84-3.54-2.91-.9-5.88-1.59-8.77-2.54-6.29-2.08-10-6.27-10.6-13.06-.64-7.6,2-13.55,8.83-17.18,2.1-1.13,3-2.21,3-4.62,0-3,2.29-4.79,5.15-4.7a4.73,4.73,0,0,1,4.81,5.07c-.1,2.28.85,3.15,2.72,4.09A15.86,15.86,0,0,1,199,293.87c0,4.49-1.66,6.57-4.57,6.86s-4.91-1.45-5.54-4.74c-1-5.07-3.3-7.2-7.49-6.86a6.65,6.65,0,0,0-6,6c-.43,3.17,1,5.31,4.29,6.37,2.48.78,5,1.37,7.54,2.08,6.61,1.85,10.89,5.92,11.64,12.95.85,8-2.44,14.55-9,17.77-2,1-2.89,2-2.83,4.34a5,5,0,1,1-10,0c0-2.35-1-3.29-2.9-4.33-5.46-2.94-8.66-7.55-9-13.89-.18-3.56,1.48-5.85,4.4-6.19s5.13,1.52,5.73,5.25c.7,4.41,4.14,7.21,7.9,6.44C187.17,325.06,189.38,321.19,188.47,317.08Z"/>
      <path fill="#fff" d="M132.57,316.15a50.43,50.43,0,0,0,55.49,41.27c28.42-3.17,48.71-30.72,43.51-59.07-4.14-22.58-23.28-39.44-46.72-41.16-21.9-1.61-43.24,12.89-50.36,34.26-1,2.94-1.75,5.95-2.61,8.93,2-.35,1.58-2.14,1.85-3.35a49.47,49.47,0,0,1,13-23.91c13.44-14,34.74-18.83,52.56-12,21.4,8.16,35.12,30.69,31.72,53.57-4.73,31.76-38.87,51.81-69.7,37.51-14.48-6.71-23.49-18.19-27.47-33.66-.33-1.29.22-3.25-1.92-3.67C132.55,315,132.47,315.63,132.57,316.15Z"/>
      <path fill="#fff" d="M426.1,157.49q-14-.17-27.9,0c-4.11,0-6.33,2.18-6.25,5.57s2.43,5.2,6.56,5.22c5.11,0,10.22,0,16.06,0-24.8,26.25-49.07,51.91-73.28,77.63-1.3,1.38-2,1.24-3.44.2-9.45-7.06-19-14-28.48-21-4.81-3.53-6.58-3.34-10.61.92q-21.52,22.78-43.08,45.54a21.63,21.63,0,0,0-2.34,2.73,5,5,0,0,0-.15,5.56,5.08,5.08,0,0,0,4.92,2.66c2-.12,3.31-1.48,4.6-2.86Q282.9,258.33,303.08,237c1.34-1.43,2.16-1.76,3.93-.43,9.23,7,18.61,13.78,27.94,20.63,5.3,3.89,6.85,3.71,11.35-1.06q36.36-38.56,72.77-77.08c.69-.72,1.22-1.71,2.51-2,0,5,0,9.79,0,14.59,0,3.86,2,6.18,5.18,6.28,3.4.12,5.53-2.12,5.57-6.21q.12-14,0-27.9C432.29,159.53,430.36,157.54,426.1,157.49Z"/>
    </SVG>
  )
}

export default Investment
