import React, { useEffect, useRef } from 'react'
import {
  Container,
  Flex,
  Text,
  Center,
  Box,
  IconButton,
  useDisclosure,
  Divider
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image';
import { IoMenu } from "react-icons/io5";
import Parallax from 'parallax-js'

import Animation from 'components/Animation';

import { Media, responsive } from 'contexts/responsive';
import Property from './Property';
import Candidate from './Candidate';
import Footer from 'containers/Footer';
import Menu from 'containers/Menu';

const covers = [
  {
    img: <StaticImage alt="yellow_card" src="cover_yellow.png" />,
    transform: responsive('translate(0, 0.5em)', 'translate(25%, -1.5em)'),
    depth: 0.3
  },
  {
    img: <StaticImage alt="white_card" src="cover_white.png" />,
    transform: responsive('translate(110%, 2.25em)', 'translate(135%, 2.25em)'),
    depth: 0.4
  },
  {
    img: <StaticImage alt="green_card" src="cover_green.png" />,
    transform: responsive('translate(17.5%, 1em)', 'translate(41%, 1em)'),
    depth: -0.4
  },
  {
    img: <StaticImage alt="purple_card" src="cover_purple.png" />,
    transform: responsive('translate(60%, 3em)', 'translate(85%, 3em)'),
    width: '51%',
    deskWidth: '40.4%',
    depth: -0.3
  },
]


const IndexPage = () => {
  const photoRef = useRef()
  useEffect(() => {
    new Parallax(photoRef.current);
  }, [])
  return (
    <Box overflow={'hidden'} >
      <Box bg="skyBlue" pt={responsive('4em', '5.875em')}>
        <Box mx={responsive('1.375em', '3.125em')}>
          <Divider borderColor="deepGreen" my="0" />
        </Box>
        <Container pt={responsive('1.625em', '2.125em')} pb={responsive('2.25em', '3.25em')}>
          <Box>
            <Box textAlign={'center'} px={responsive('1.625em', '0')}>
              <Text fontSize={responsive('1em', '1.875em')} mt={responsive('1em', '2rem')} textAlign="center" fontWeight="600">
                全台地方首長候選人的財產申報<br />
                你看過了嗎？
              </Text>
              <Text fontSize={responsive('0.875em', '1.125em')} mt={responsive('1rem', '1.5rem')} fontWeight="400">
                {/* <Media greaterThanOrEqual='md'> */}
                    自2007年起，這條法規上路後，資料依然是難以閱讀、查找的pdf格式，<br />
                    且申報資料的完整性仍有待努力……<br />

                {/* </Media> */}
                {/* <Media lessThan='md'>
                  2022年，中選會首次將候選人的申報資料上網公告。但根據過去經驗，剛上路的資料，通常都非常難使用。<br />
                </Media> */}
                <br />
                  為了讓大家更方便使用、關注，<br />
                  我們花了60個小時以上的時間重新整理資料格式，又花了許多時間製作這個網站，<br />
                  希望公開資料的便利性更加受到重視！
              </Text>
            </Box>
            <Box ref={photoRef} pt={responsive('2em', "5.5em")} pb={responsive('3em', '1.5em')}>
              {covers.map(({ img, depth, transform, width, deskWidth }, i) => (
                <Box data-depth={depth} key={i}>
                  <Box pos="relative" width={responsive(width || '45.3%', deskWidth || '35.6%')} transform={transform} >
                    {img}
                  </Box>
              </Box>
              ))}
            </Box>
            <Text textAlign={'center'} fontSize={responsive('0.5em', '1.125em')}>
              {/* 想知道縣市長候選人的土地、建物在哪個路段<br />
              現金存款、有價證券、保險有多少...<br /> */}
              繼續往下看！
            </Text>
            <Center pt={responsive('0.5em', '0.75em')}>
              <Animation>
                <Box width={responsive('0.375em', "0.5em")}>
                  <StaticImage alt="arrow" src="arrow.png" layout='fullWidth' />
                </Box>
              </Animation>
            </Center>
          </Box>
        </Container>
      </Box>
      <Property />
      <Candidate />
      <Footer />
    </Box>
  )
}

export default IndexPage
