import SVG from 'components/SVG'
import React from 'react'

const Building = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.6,42.27l0,.06a61.14,61.14,0,0,0,83.85,34.72l0,0c52-24.7,106.15,29.5,81.46,81.46l0,0a61.14,61.14,0,0,0,34.73,83.85l.05,0c54.2,19.27,54.2,95.93,0,115.2l-.05,0A61.13,61.13,0,0,0,523,441.46l0,.05c24.69,52-29.51,106.16-81.46,81.46l0,0a61.14,61.14,0,0,0-83.85,34.72l0,.06c-19.28,54.2-95.92,54.2-115.2,0l0-.06A61.14,61.14,0,0,0,158.53,523l-.05,0c-51.95,24.7-106.16-29.5-81.45-81.46l0,0a61.14,61.14,0,0,0-34.73-83.85l0,0c-54.2-19.27-54.2-95.93,0-115.2l0,0a61.14,61.14,0,0,0,34.73-83.85l0,0c-24.71-52,29.5-106.16,81.45-81.46l.05,0a61.14,61.14,0,0,0,83.85-34.72l0-.06C261.68-11.93,338.32-11.93,357.6,42.27Z"/>
      <path fill="#f5f5f5" d="M349.18,141.26q-52.83.27-105.66,0c-5.25,0-9.16,3.48-9.16,9.23q.19,127.8.09,255.6h23.26V367.84c0-6.3,3-9.32,9.32-9.32h58.9c6.3,0,9.31,3,9.32,9.32v38.25h23.26q0-127.68.09-255.34C358.61,144.73,354.84,141.23,349.18,141.26ZM265.44,343a7.75,7.75,0,1,1,7.76-7.72A8,8,0,0,1,265.44,343Zm-.06-31a7.75,7.75,0,1,1,7.82-7.67A8,8,0,0,1,265.38,312Zm.15-31a7.74,7.74,0,1,1,7.67-7.81A8,8,0,0,1,265.53,280.94Zm-.15-31a7.75,7.75,0,1,1,7.82-7.66A8,8,0,0,1,265.38,249.92Zm.18-31a7.75,7.75,0,1,1,7.64-7.84A8,8,0,0,1,265.56,218.9Zm-.14-31a7.75,7.75,0,1,1,7.78-7.7A8,8,0,0,1,265.42,187.87ZM296.54,343a7.75,7.75,0,1,1,7.68-7.8A8,8,0,0,1,296.54,343Zm.06-31a7.75,7.75,0,1,1,7.62-7.87A8,8,0,0,1,296.6,312Zm0-31a7.74,7.74,0,1,1,7.66-7.82A8,8,0,0,1,296.56,280.94Zm0-31a7.75,7.75,0,1,1,7.66-7.82A8,8,0,0,1,296.56,249.92Zm-.07-31a7.75,7.75,0,1,1,7.73-7.76A8,8,0,0,1,296.49,218.9Zm-.1-31a7.75,7.75,0,1,1,7.83-7.65A8,8,0,0,1,296.39,187.87ZM327.47,343a7.75,7.75,0,1,1,7.76-7.72A8,8,0,0,1,327.47,343Zm.09-31a7.75,7.75,0,1,1,7.67-7.81A8,8,0,0,1,327.56,312Zm-.14-31a7.74,7.74,0,1,1,7.81-7.67A8,8,0,0,1,327.42,280.94Zm0-31a7.75,7.75,0,1,1,7.83-7.65A8,8,0,0,1,327.4,249.92Zm-.11-31a7.75,7.75,0,1,1,7.94-7.54A8,8,0,0,1,327.29,218.9Zm.27-31a7.75,7.75,0,1,1,7.67-7.81A8,8,0,0,1,327.56,187.87Z"/>
      <path fill="#f5f5f5" d="M218.08,375V209.39c0-5.13,0-5.13-5.06-5.13H174.28a10.9,10.9,0,0,0-5.05.83,6.46,6.46,0,0,0-3.54,5.09,28,28,0,0,0-.16,4.39q0,89.93,0,179.84c0,4.22.19,8.14,4.34,10.57a1.07,1.07,0,0,1,.51,1.11h14c0-13.52-.12-27,0-40.54,0-3.87,3.67-7,7.54-7s7.5,3.14,7.53,7c.08,13.51,0,27,0,40.54h19.64c-1-1.16-.85-2.58-.85-3.94Q218.06,388.58,218.08,375Zm-26.16-32a7.75,7.75,0,1,1,7.49-7.67A7.73,7.73,0,0,1,191.92,343Zm0-31a7.75,7.75,0,1,1,7.45-7.71A7.71,7.71,0,0,1,192,312Zm-.34-31a7.75,7.75,0,1,1,7.79-7.62A7.72,7.72,0,0,1,191.62,280.94Zm.3-31a7.75,7.75,0,1,1,7.49-7.66A7.71,7.71,0,0,1,191.92,249.92Z"/>
      <path fill="#f5f5f5" d="M424.13,404.17a7.52,7.52,0,0,0,3.28-6.4c0-1,0-2.07,0-3.1V214.75c0-8.62-1.86-10.49-10.45-10.49H380c-5.13,0-5.14,0-5.14,5.05q0,95.78,0,191.55c0,1.76.43,3.64-.84,5.23h19.64c0-13.52-.12-27,0-40.54,0-3.87,3.67-7,7.53-7s7.51,3.14,7.54,7c.08,13.51,0,27,0,40.54h14C422.58,405,423.46,404.65,424.13,404.17Zm-23-169.75a7.75,7.75,0,1,1-7.6,7.56A7.71,7.71,0,0,1,401.15,234.42Zm0,108.58a7.75,7.75,0,1,1,7.63-7.78A7.73,7.73,0,0,1,401.12,343Zm0-31a7.75,7.75,0,1,1,7.66-7.75A7.71,7.71,0,0,1,401.09,312ZM401,281a7.75,7.75,0,1,1,7.76-7.66A7.72,7.72,0,0,1,401,281Z"/>
      <path fill="#f5f5f5" d="M428.36,396.27q-.15-92.14-.07-184.28c0-5.56-3-8.59-8.59-8.59-14.3,0-28.59.07-42.89-.07-2.46,0-2.84.74-2.84,3q.09,99.88,0,199.79c1.27-1.59.84-3.47.84-5.23q0-95.78,0-191.55c0-5.05,0-5.05,5.14-5.05h37c8.59,0,10.45,1.87,10.45,10.49V394.67c0,1,0,2.06,0,3.1a7.52,7.52,0,0,1-3.28,6.4c-.67.48-1.55.85-1.52,1.92C426.9,404.2,428.37,400.94,428.36,396.27Z"/>
      <path fill="#f5f5f5" d="M318.88,378.37c0-3.31-.18-3.46-3.53-3.49-2.33,0-4.65,0-7,0-3,0-3.26.23-3.27,3.22,0,7.75,0,15.51,0,23.26,0,1.6.34,3.3-.83,4.72h15.5c-1-1.16-.84-2.58-.84-3.95C318.88,394.22,318.91,386.29,318.88,378.37Z"/>
      <path fill="#f5f5f5" d="M317.67,374c-3.78.13-7.58.13-11.37,0-1.69-.06-2.12.43-2.11,2.11.09,10,.05,20,.05,30,1.17-1.42.82-3.12.83-4.72,0-7.75,0-15.51,0-23.26,0-3,.24-3.19,3.27-3.22,2.33,0,4.65,0,7,0,3.35,0,3.52.18,3.53,3.49,0,7.92,0,15.85,0,23.77,0,1.37-.17,2.79.84,3.95,0-10,0-20,0-30C319.8,374.42,319.37,373.93,317.67,374Z"/>
      <path fill="#f5f5f5" d="M287.87,378.37c0-3.32-.19-3.46-3.54-3.49-2.32,0-4.65,0-7,0-3,0-3.26.23-3.28,3.22,0,7.75,0,15.51,0,23.26,0,1.6.33,3.3-.84,4.72h15.51c-1-1.16-.84-2.58-.84-3.95C287.86,394.22,287.89,386.29,287.87,378.37Z"/>
      <path fill="#f5f5f5" d="M286.66,374q-5.69.19-11.37,0c-1.7-.06-2.12.43-2.11,2.11.08,10,0,20,0,30,1.17-1.42.83-3.12.84-4.72,0-7.75,0-15.51,0-23.26,0-3,.25-3.19,3.28-3.22,2.32,0,4.65,0,7,0,3.35,0,3.53.17,3.54,3.49,0,7.92,0,15.85,0,23.77,0,1.37-.18,2.79.84,3.95,0-10,0-20,0-30C288.78,374.42,288.35,373.93,286.66,374Z"/>
    </SVG>
  )
}

export default Building
