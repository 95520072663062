import React from 'react'
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'

const MedalModal = ({ isOpen, onClose, data, color }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      {/* <ModalOverlay /> */}
      <ModalContent
        width={responsive("17em", '25em')}
        fontFamily={'noto'}
        bg="rgba(245, 245, 245, 0.9)"
        px={responsive('0.875em', '1.25em')}
        py={responsive('0.5em', '1em')}
      >
        <ModalCloseButton borderRadius={'50%'} color={color} bg="white" width={responsive("1.375rem", '2rem')} height={responsive("1.375rem", '2rem')} />
        <Flex justifyContent={'center'}>
          <Flex flexDir={'column'} fontWeight={'bold'} fontSize={responsive('0.875em', '1.5em')}>
            <Box>此候選人</Box>
            <Box>獲「{data?.name}」徽章！</Box>
          </Flex>
        </Flex>
        <Text fontSize={responsive('0.75em', '1.25em')} mt="0.375rem">
          代表在95位縣市長候選人中，「{data?.name}」數據為前10%高的，獲頒此徽章！
        </Text>
        <Box pos="absolute" left={responsive('-1.25em', '-1.75em')} top={responsive('-0.75em', '-1em')} width={responsive('4em', '5em')}>
          {data?.IconSvg}
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default MedalModal
