import SVG from 'components/SVG'
import React from 'react'

const Security = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.6,42.27l0,0A61.14,61.14,0,0,0,441.48,77h0c52-24.7,106.15,29.5,81.46,81.46l0,0a61.14,61.14,0,0,0,34.73,83.85l.05,0c54.2,19.27,54.2,95.93,0,115.2l-.05,0A61.13,61.13,0,0,0,523,441.46l0,.05c24.69,52-29.51,106.16-81.46,81.46l0,0a61.14,61.14,0,0,0-83.85,34.72l0,.06c-19.28,54.2-95.92,54.2-115.2,0l0-.06A61.14,61.14,0,0,0,158.53,523l-.05,0c-51.95,24.7-106.15-29.5-81.45-81.46l0-.05a61.13,61.13,0,0,0-34.73-83.84l0,0c-54.2-19.27-54.2-95.93,0-115.2l0,0a61.13,61.13,0,0,0,34.73-83.84l0,0c-24.7-52,29.5-106.16,81.45-81.46l.05,0a61.14,61.14,0,0,0,83.85-34.72l0-.06C261.68-11.93,338.32-11.93,357.6,42.27Z"/>
      <path fill="#fff" d="M405.21,255.69q0-41.1,0-82.19c0-8.39-5.59-13.92-13.89-13.92h-192c-.46,0-.92,0-1.37,0a12,12,0,0,0-8.53,3.78c-3,3-4.11,6.67-4.1,10.93q.06,125.76,0,251.51c0,8.38,3.38,12.72,11.31,14.62h63.57c.81-.88,1.88-.53,2.84-.53H393.9c0,.09,0,.18-.08.26H394a9.81,9.81,0,0,0,7.24-3.33c3.11-3.15,4-6.93,4-11.25Q405.16,340.63,405.21,255.69Zm-136,20.41a8,8,0,0,1,11.1-2.3,24.24,24.24,0,0,0,15.31,3.84A12,12,0,0,0,306.82,264c-.61-3.69-3.05-5.65-6.28-6.87a145.64,145.64,0,0,1-17.5-7.44c-8.39-4.5-13.49-11-13-20.95.49-10.06,5.3-17.42,14.52-21.62a2.9,2.9,0,0,0,2.07-3.17c0-5.16,3.42-8.93,8-8.93a8.21,8.21,0,0,1,8.28,8.88c-.07,1.32.37,1.83,1.71,2.18a27.62,27.62,0,0,1,8.84,4.14,7.88,7.88,0,0,1,3.8,6.86,7.45,7.45,0,0,1-4.08,7.19,7.81,7.81,0,0,1-8.55-.39,14.6,14.6,0,0,0-13-2,8,8,0,0,0-5.13,9.64c.45,1.56,1.75,2.27,3,3a72.2,72.2,0,0,0,14.66,6.5c9.17,3.16,15.94,8.8,18.41,18.48,3.3,13-4.16,27.26-16.79,32.27-1.95.78-2.53,1.63-2.53,3.67,0,6.63-5.34,10.67-11,8.52a7.62,7.62,0,0,1-5.15-7.33c.17-2.67-1-3.25-3.24-3.66a31.65,31.65,0,0,1-12.77-5.88A8.07,8.07,0,0,1,269.16,276.1Zm-44.61,53.07a7.56,7.56,0,0,1,6.42-5.58,20,20,0,0,1,3-.16H356.61c5.06,0,8.11,1.83,9.31,5.55a8,8,0,0,1-7.61,10.64c-9.49.1-19,0-28.47,0H295.07q-31.2,0-62.41,0C226.73,339.62,222.85,334.58,224.55,329.17ZM233.28,356h44.93c5.38,0,9,3.28,9,8.14s-3.56,8.23-8.94,8.25q-22.6.07-45.2,0c-5.21,0-9.08-3.73-9-8.34S228,356,233.28,356Zm130,47.39a9.05,9.05,0,0,1-5.86,1.89q-62.16,0-124.31,0c-5.21,0-9.07-3.66-9-8.29s3.89-8.1,9.13-8.1q31.08,0,62.16,0t62.15,0c4,0,7.19,2.11,8.35,5.41A8.15,8.15,0,0,1,363.27,403.42Zm-5.45-31c-7.57.12-15.14,0-22.71,0s-14.95.08-22.43,0c-6.59-.09-10.65-6-8-11.59a7.76,7.76,0,0,1,7.36-4.77q23.12-.09,46.24,0a8.28,8.28,0,0,1,8.16,8.21A8.38,8.38,0,0,1,357.82,372.4Z"/>
      <path fill="#fff" d="M382.82,440.14q-59.71,0-119.41,0c-1.08,0-2.2-.28-3.23.26H382.92v-.28Z"/>
      <path fill="#fff" d="M263.41,440.16q59.7,0,119.41,0h.11v-.26H263c-1,0-2-.35-2.84.53C261.21,439.88,262.33,440.16,263.41,440.16Z"/>
      <path fill="#fff" d="M383.11,440.25c-.06.12-.12.17-.19.17h11c0-.06-.09-.12-.09-.18l0-.1H383.06S383.12,440.21,383.11,440.25Z"/>
      <path fill="#fff" d="M382.93,439.88a.29.29,0,0,1,.13.26h10.76c0-.08.05-.17.08-.26Z"/>
      <path fill="#fff" d="M383.06,440.14h-.13v.28c.07,0,.13-.05.19-.17S383.06,440.18,383.06,440.14Z"/>
      <path fill="#fff" d="M383.06,440.14a.29.29,0,0,0-.13-.26v.26Z"/>
    </SVG>
  )
}

export default Security
