import React from 'react'
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import { WiMoonFirstQuarter } from 'react-icons/wi'
import styled from '@emotion/styled'
import range from 'lodash/range'
import set from 'lodash/set'
import isObject from 'lodash/isObject'
import Button from 'components/Button'

const StyledCheckbox = styled(Checkbox)`
  .chakra-checkbox__label {
    font-size: 1.5em;
  }
  .chakra-checkbox__control {
    border-width: 0.5px;
    width: 36px;
    height: 36px;
    background-color: white;
    svg { font-size: 1em; }
  }
  .chakra-checkbox__control[data-checked] {
    background-color: black;
  }
  .chakra-checkbox__control[data-checked][data-hover] {
    background-color: black;
  }
  // .chakra-checkbox__control[data-hover], .chakra-checkbox__control[data-indeterminate] {
  //   background-color: black;
  // }
  .chakra-checkbox__control[data-focus] {
    box-shadow: none;
  }
  @media screen and (max-width: 480px) {
    .chakra-checkbox__control {
      width: 15px;
      height: 15px;
      svg { font-size: 0.75em; }
    }
    .chakra-checkbox__label {
      font-size: 1rem;
    }
    .chakra-checkbox__control[data-hover] {
      background-color: white;
    }
  }
`

const Filter = ({ checkedItems, setCheckedItems, setFilter, filters }) => {
  return (
    <Box
      pos="absolute"
      top="0"
      left="0"
      right="0"
      bg="filterBg"
      px={responsive('1em', '4em')}
      pt={responsive('1em', '4.375em')}
      pb={responsive('1em', '2.625em')}
    >
      {filters.map(({ name, options }, k) => (
        <Box key={k} pt={k && '1em'} borderBottom="1px solid #89897B" pb="2.5em">
          <Flex justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
              <Box transform={'rotate(22.5deg)'} mr="0.5em"><WiMoonFirstQuarter size="1.25em" /></Box>
              <Text fontWeight={500} fontSize={responsive('1em', '2em')}>{name}</Text>
            </Flex>
            {!!k && (
              <StyledCheckbox
                isChecked={checkedItems[k].every(Boolean)}
                // isIndeterminate={checkedItems[k].some(Boolean) && !checkedItems[k].every(Boolean)}
                borderColor="#89897B"
                onChange={() => {
                  if (checkedItems[k].every(Boolean)) {
                    const cancel = range(checkedItems[k].length).fill(false)
                    set(checkedItems, [k], cancel)
                    setCheckedItems([...checkedItems])
                    return
                  }
                  set(checkedItems, [k], filters[k].options)
                  setCheckedItems([...checkedItems])
                  if (typeof window.gtag === 'function') {
                    window.gtag("event", "click_button", {
                      button_name: `${name}-all`
                    });
                  }
                }}
              >
                全選
              </StyledCheckbox>
            )}
          </Flex>
          <Flex pt={responsive('0.5em', '0.75em')} flexWrap="wrap">
            {options.map((opt, i) => (
              <Box mr='1.5rem' pt="0.5em" key={i}>
                <StyledCheckbox
                  icon={<div />}
                  borderColor="#89897B"
                  disabled={!k && checkedItems[k].includes(true) && !checkedItems[k][i] }
                  isChecked={checkedItems[k][i]}
                  onChange={(e) => {
                    set(checkedItems, [k, i], e.target.checked)
                    setCheckedItems([...checkedItems])
                    if (typeof window.gtag === 'function') {
                      window.gtag("event", "click_button", {
                        button_name: isObject(opt) ? opt.name : opt
                      });
                    }
                  }}
                >
                  {isObject(opt) ? opt.name : opt}
                </StyledCheckbox>
              </Box>
            ))}
          </Flex>
        </Box>
      ))}
      <Box textAlign={'center'} mt="1em">
        <Button
          onClick={() => {
            setFilter(false)
            if (typeof window.gtag === 'function') {
              window.gtag("event", "click_button", {
                button_name: 'comfirm'
              });
            }
          }}
        >確認</Button>
      </Box>
    </Box>
  )
}

export default Filter
