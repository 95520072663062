import React, { useState } from 'react'
import { BsQuestion } from 'react-icons/bs'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Circle,
  Flex,
  Text } from '@chakra-ui/react'
import round from 'lodash/round'
import { responsive } from 'contexts/responsive'
import { addComma } from './CandidatesModal'
import { properties } from '../Property'
import Item from '../Item'

const Label = ({ bg, name, note, bgMedal, total, unit, transit, quantity, setOpenPro }) => (
  <Flex px={responsive('1em', '3em')} py={responsive('0.75em', '1.5em')} bg={bg} alignItems="center" justifyContent={'space-between'}>
    <Box>
      <Flex alignItems={'center'}>
        <Text fontWeight={600} fontSize={responsive('0.875em', '1.875em')}>{name}</Text>
        <Circle onClick={setOpenPro} ml={responsive('0.5em', '1rem')} size="1em" fontSize={responsive('1em', '1.875em')} color="white" bg={bgMedal}>
          <BsQuestion />
        </Circle>
      </Flex>
      <Text textAlign={'left'} fontSize={responsive('0.75em', '1.125em')} fontWeight={500} fontFamily="noto">{note}</Text>
    </Box>
    <Flex width={responsive('45%', '40%')} pr={responsive('1.125em', '1em')} flexDir={responsive('column', 'row')} alignItems={responsive('flex-start', 'center')}>
      <Text fontWeight={500} fontFamily="noto" mt="0.875rem" fontSize={responsive('0.75em', '0.875em')} mr="1em">{transit}{quantity}</Text>
      <Flex alignItems={'center'}>
        <Text fontWeight={700} fontSize={responsive('1em', '2.5em')}>{addComma(total)}</Text>
        <Text fontWeight={700} fontFamily="noto" mt={responsive(0, "0.875rem")} fontSize={responsive('0.75em', '0.875em')} ml={responsive('0.25em', "0.75em")}>{unit}</Text>
      </Flex>
    </Flex>
  </Flex>
)

const perPrice = (info) => {
  const arr = /(\d+)([^\d]+)/g.exec(info)
  return (
    <Flex alignItems={'center'}>
      <Text>{addComma(arr[1])}</Text>
      <Text fontFamily={'noto'} ml="0.25rem" fontSize={responsive('0.75rem', '0.875rem')}>{arr[2]}</Text>
    </Flex>
  )
}

const Possession = ({ addNumPropertis, partyData }) => {
  const [proOpen, setOpenPro] = useState()
  return (
    <Box>
      {addNumPropertis.map((d, i) => d?.detail ? (
        <Accordion allowToggle allowMultiple key={i}>
          <AccordionItem borderBottom="none" borderTop="none">
            <AccordionButton pos="relative" _hover={{}} bg={partyData?.estateBg?.[i]} p="0">
              <Box flex={1}>
                <Label
                  setOpenPro={() => setOpenPro(i.toString())}
                  name={d.name}
                  note={d.note}
                  bgMedal={partyData?.btnBg}
                  transit={d.transit}
                  quantity="總面積"
                  total={d.total}
                  unit="平方公尺"
                  key={i}
                />
              </Box>
              <AccordionIcon pos="absolute" top="50%" right={responsive('2rem', '3rem')} transform="translate(100%, -50%)" />
            </AccordionButton>
            <AccordionPanel color="textGray" px={responsive('1.375em', '3.625em')} py={responsive('1.875em')} bg="white">
              <Box maxHeight={responsive('20em', '18.75em')} overflow="auto">
                {d?.detail?.map((info, i) => (info.note || typeof info.place != 'undefined' || Boolean(+info.area)) ? (
                  <Flex alignItems={responsive('flex-start', 'center')} mt={i && '1em'} key={i}>
                    <Box width={'2em'}>
                      <Box display={'inline-block'} pb={responsive('2px')} borderBottom="1px solid" fontWeight={'bold'} fontSize={responsive('1em', '1.5em')}>{i + 1}</Box>
                    </Box>
                    <Flex alignItems={responsive('flex-start', 'center')} flexDir={responsive('column', 'row')} justifyContent={'space-between'} ml={responsive('1.25rem', '2.5em')} flex={1}>
                      <Text fontFamily={'noto'} fontSize={responsive('0.75em', '0.875em')} width={responsive('auto', '30%')} mr="1.25em">{info.place}</Text>
                      <Flex width="100%" alignItems={'center'} justifyContent={'space-between'}>
                        <Box width={responsive('unset', '32%')}>
                          {Boolean(+info.area) && (
                            <>
                              <Text fontWeight={600} fontSize={responsive('1.25em', '1.5em')}>
                                {round(info.area, 2)}
                                <Text fontFamily={'noto'} as="span"  ml="0.25rem" fontWeight={400} fontSize={responsive('0.75rem', '0.875rem')}>平方公尺</Text>
                              </Text>
                              <Text fontFamily={'noto'} fontSize={responsive('0.75em', '0.875em')}>持分面積 ( 面積x持分 )</Text>
                            </>
                          )}
                        </Box>
                        <Box width={responsive('unset', '40%')}>
                          {info.price ? (
                            <>
                              <Text fontWeight={600} fontSize={responsive('1.25em', '1.5em')}>
                                {info?.price?.includes('五') ? <Text fontFamily={'noto'}>{info.price}</Text> : info?.price?.includes('元') ? perPrice(info.price) : addComma(round(info?.price, 2))}
                                {!info?.price?.includes('元') && (
                                  <Text fontFamily={'noto'} ml="0.25rem" as="span" fontWeight={400} fontSize={responsive('0.75rem')}>
                                    {info?.price?.includes('五') ? '未申報' : '元'}
                                  </Text>
                                )}
                              </Text>
                              <Text fontFamily={'noto'} fontSize={responsive('0.75em', '0.875em')}>
                                {info?.price?.includes('五') ? '無公告現值、市價或價額' : info?.price?.includes('元') ? '年度土地公告現值或市價' : '取得價額總額(估算值)'}
                              </Text>
                              {info.note && <Text fontSize={responsive('0.75em', '0.875em')}>{info.note}</Text>}
                            </>
                          ) : (
                            <Text fontFamily={'noto'} fontWeight={600} fontSize={responsive('0.75em', '0.875em')}>{info.note}</Text>
                          )}
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                ) : (
                  <Text fontFamily={'noto'} fontSize={responsive('0.75rem', '1em')}>候選人未申報相關資料</Text>
                ))}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <Label
          bg={partyData?.estateBg?.[i]}
          setOpenPro={() => setOpenPro(i.toString())}
          name={d.name}
          note={d.note}
          bgMedal={partyData?.btnBg}
          transit={d.transit}
          quantity="總金額"
          total={d.total}
          unit="元"
          key={i}
        />
      ))}
      {proOpen && (
        <Item isOpen={proOpen} data={properties?.[proOpen]} onClose={() => setOpenPro(false)} />
      )}
    </Box>
  )
}

export default Possession
