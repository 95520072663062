import SVG from 'components/SVG'
import React from 'react'

const ArrowDown = (props) => {
  return (
    <SVG viewBox="0 0 4 17" {...props}>
      <path d="M1.82322 16.1768C1.92085 16.2744 2.07915 16.2744 2.17678 16.1768L3.76777 14.5858C3.8654 14.4882 3.8654 14.3299 3.76777 14.2322C3.67014 14.1346 3.51184 14.1346 3.41421 14.2322L2 15.6464L0.585786 14.2322C0.488155 14.1346 0.329864 14.1346 0.232233 14.2322C0.134602 14.3299 0.134602 14.4882 0.232233 14.5858L1.82322 16.1768ZM1.75 0L1.75 16H2.25L2.25 0L1.75 0Z" fill="currentColor" />
    </SVG>
  )
}

export default ArrowDown
