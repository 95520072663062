import SVG from 'components/SVG'
import React from 'react'

const Cash = (props) => {
  return (
    <SVG viewBox="0 0 600 600" {...props}>
      <path fill="currentColor" d="M357.59,42.25l0,.09a61.13,61.13,0,0,0,83.84,34.72l.05,0c52-24.7,106.16,29.5,81.46,81.46v0a61.13,61.13,0,0,0,34.72,83.85l.05,0c54.19,19.28,54.19,95.92,0,115.2l-.05,0A61.14,61.14,0,0,0,523,441.49l0,0c24.69,52-29.51,106.15-81.47,81.46l0,0a61.14,61.14,0,0,0-83.86,34.72l0,0c-19.29,54.21-96,54.19-115.21,0h0A61.15,61.15,0,0,0,158.53,523l0,0c-52,24.69-106.16-29.51-81.46-81.47v0a61.14,61.14,0,0,0-34.72-83.86l0,0c-54.19-19.28-54.19-95.92,0-115.2l0,0A61.14,61.14,0,0,0,77,158.53v0c-24.7-52,29.5-106.16,81.45-81.46l.05,0a61.13,61.13,0,0,0,83.86-34.75v0C261.65-11.93,338.31-11.95,357.59,42.25Z"/>
      <path fill="#f5f5f5" d="M424.94,202.52c-3.24-10.23-11-16-21.72-16H331.93c-.58,0-1.16,0-1.73,0-1.51-.05-2.3.56-2.29,2.16q0,6.9,0,13.81c0,1.74.89,2.29,2.48,2.27,2.3,0,4.6,0,6.9,0h42.93c7.19,0,9.7,2.14,11,9.22.66,3.74,3,6,7,6.52A9.6,9.6,0,0,1,405,224a9,9,0,0,1,1.85,5.76q0,38.49,0,77c0,5.23-3,8.36-8.2,9.11-4.72.69-6.93,2.84-7.56,7.37-.79,5.63-3.9,8.42-9.65,8.44-16.36.06-32.73,0-49.09,0-4.43,0-4.43,0-4.43,4.52,0,3.45,0,6.91,0,10.36,0,3.2.16,3.36,3.33,3.36q16.53,0,33.06,0h39c10.4,0,18-5.47,21.51-15.31.3-.85.28-2,1.59-2.21V204.55C425.11,204.42,425.19,203.31,424.94,202.52Z"/>
      <path fill="#f5f5f5" d="M405.06,185.82c-25.33-.06-50.66,0-76-.07-1.73,0-2,.59-1.95,2.08.08,4.94.16,9.88,0,14.81-.08,2.27.36,3,2.85,3,17.18-.14,34.37-.08,51.56-.07,5.67,0,8.4,2.55,8.84,8.23a8.38,8.38,0,0,0,7.5,7.55c5.84.48,8.28,3.13,8.28,9v75.75c0,5.88-2.45,8.54-8.3,9a8.42,8.42,0,0,0-7.47,7.32c-.62,6.25-3.07,8.46-9.36,8.46-17.27,0-34.54,0-51.81,0-1.51,0-2.12.25-2.08,2,.13,5.26.14,10.53,0,15.79-.05,1.85.62,2.07,2.23,2.06,25-.05,50,0,75,0a22.29,22.29,0,0,0,20.44-13.72c.62-1.48.66-3.16,1.59-4.54-1.31.17-1.29,1.36-1.59,2.21-3.48,9.84-11.11,15.3-21.51,15.31h-39q-16.53,0-33.06,0c-3.17,0-3.31-.16-3.33-3.36,0-3.45,0-6.91,0-10.36,0-4.51,0-4.52,4.43-4.52,16.36,0,32.73,0,49.09,0,5.75,0,8.86-2.81,9.65-8.44.63-4.53,2.84-6.68,7.56-7.37,5.23-.75,8.19-3.88,8.2-9.11q.12-38.48,0-77A9,9,0,0,0,405,224a9.6,9.6,0,0,0-6.83-3.46c-4-.49-6.35-2.78-7-6.52-1.25-7.08-3.76-9.21-11-9.22H337.28c-2.3,0-4.6,0-6.9,0-1.59,0-2.48-.53-2.48-2.27q0-6.9,0-13.81c0-1.6.78-2.21,2.29-2.16.57,0,1.15,0,1.73,0h71.29c10.72,0,18.48,5.75,21.72,16,.25.79.17,1.9,1.42,2-.58-.27-.4-.84-.49-1.28C424,193.6,414.84,185.84,405.06,185.82Z"/>
      <path fill="#f5f5f5" d="M423.78,360.63a39.49,39.49,0,0,1-20.2,5.58q-35.38,0-70.77,0c-.74,0-1.48,0-2.22,0-1.83-.11-2.73.61-2.7,2.56.06,3.45,0,6.91,0,10.36,0,1.68.73,2.41,2.37,2.35.58,0,1.15,0,1.73,0h71.27c10.39,0,18-5.45,21.49-15.31.31-.86.29-2,1.6-2.2v-5.93A6.21,6.21,0,0,1,423.78,360.63Z"/>
      <path fill="#f5f5f5" d="M329.39,365.42c-1.83,0-2.36.39-2.29,2.26.16,4.11.15,8.23,0,12.33-.06,1.83.4,2.29,2.26,2.29,25-.07,50,0,75-.09,8.91,0,15.45-4.35,19.61-12.17a57.73,57.73,0,0,0,2.41-6.05c-1.31.17-1.29,1.34-1.6,2.2-3.5,9.86-11.1,15.3-21.49,15.31H332c-.58,0-1.15,0-1.73,0-1.64.06-2.38-.67-2.37-2.35,0-3.45.05-6.91,0-10.36,0-1.95.87-2.67,2.7-2.56.74,0,1.48,0,2.22,0q35.38,0,70.77,0a39.49,39.49,0,0,0,20.2-5.58,6.21,6.21,0,0,0,2.58-2.57c-7.53,5.42-16,7.5-25.2,7.45C377.23,365.38,353.31,365.49,329.39,365.42Z"/>
      <path fill="#f5f5f5" d="M423.78,392.22a39.49,39.49,0,0,1-20.2,5.58q-35.38,0-70.77,0c-.74,0-1.48,0-2.22,0-1.83-.11-2.73.61-2.7,2.56.06,3.45,0,6.91,0,10.36,0,1.68.73,2.41,2.37,2.35.58,0,1.15,0,1.73,0h71.27c10.39,0,18-5.45,21.49-15.31.31-.85.29-2,1.6-2.2v-5.93A6.15,6.15,0,0,1,423.78,392.22Z"/>
      <path fill="#f5f5f5" d="M329.39,397c-1.83,0-2.36.39-2.29,2.26.16,4.11.15,8.23,0,12.33-.06,1.83.4,2.29,2.26,2.29,25-.07,50,0,75-.09,8.91,0,15.45-4.35,19.61-12.17a57.73,57.73,0,0,0,2.41-6.05c-1.31.17-1.29,1.35-1.6,2.2-3.5,9.86-11.1,15.3-21.49,15.31H332c-.58,0-1.15,0-1.73,0-1.64.06-2.38-.67-2.37-2.35,0-3.45.05-6.91,0-10.36,0-1.95.87-2.67,2.7-2.56.74,0,1.48,0,2.22,0q35.38,0,70.77,0a39.49,39.49,0,0,0,20.2-5.58,6.15,6.15,0,0,0,2.58-2.57c-7.53,5.42-16,7.5-25.2,7.45C377.23,397,353.31,397.08,329.39,397Z"/>
      <path fill="#f5f5f5" d="M270.39,185.74c-24.59.08-49.18,0-73.77.06-13.42,0-23,9.52-23,22.91q0,29.73,0,59.46h0c0,20.39,0,40.79,0,61.19,0,10.95,9.8,21.17,20.6,21.23,25.66.16,51.32.05,77,.1,1.3,0,1.64-.36,1.63-1.65-.07-5.26-.13-10.52,0-15.78,0-1.95-.5-2.4-2.41-2.39-17.43.08-34.86.05-52.3,0-5.29,0-8.17-2.75-8.52-8a8.3,8.3,0,0,0-7.82-7.75c-5.23-.33-8-3.26-8-8.59q0-38.25,0-76.49c0-5.5,2.65-8.33,8.1-8.71,3.95-.28,7.59-3.51,7.61-7.39,0-5.07,3.58-8.53,9.23-8.47,17.27.18,34.54,0,51.8.11,1.85,0,2.33-.42,2.28-2.27-.15-5-.17-10,0-15.05C273,186.2,272.43,185.73,270.39,185.74Z"/>
      <path fill="#f5f5f5" d="M327.11,314.12c0,1.59.39,2,2,2q23-.09,45.89,0a2.24,2.24,0,0,0,2.47-1.66,22,22,0,0,1,12.2-12.2,2.26,2.26,0,0,0,1.67-2.48q-.08-31.57,0-63.16a2.26,2.26,0,0,0-1.67-2.47A21.85,21.85,0,0,1,377.5,222c-.6-1.46-1.41-1.64-2.75-1.64-15.13,0-30.26.06-45.39,0-1.88,0-2.26.47-2.25,2.29.08,15.13,0,30.26,0,45.39C327.15,283.36,327.18,298.74,327.11,314.12Zm24.48-53.28q7.64-.12,15.28,0a7.39,7.39,0,1,1,0,14.77c-2.54.08-5.09,0-7.63,0s-5.1.06-7.64,0a7.39,7.39,0,1,1,0-14.77Z"/>
      <path fill="#f5f5f5" d="M270.87,220.29c-15.3.1-30.59.08-45.89,0a2.23,2.23,0,0,0-2.46,1.68,22,22,0,0,1-12,12.1,2.56,2.56,0,0,0-1.87,2.83q.09,31.34,0,62.67a2.55,2.55,0,0,0,1.93,2.78,21.94,21.94,0,0,1,11.86,11.91,2.55,2.55,0,0,0,2.84,1.86c15.13-.06,30.26-.08,45.39,0,1.92,0,2.24-.54,2.23-2.31-.07-15.14,0-30.27,0-45.4,0-15.3,0-30.6,0-45.89C272.89,221,272.79,220.28,270.87,220.29Zm-22.46,55.32q-7.64.12-15.27,0a7.39,7.39,0,1,1,0-14.77c2.54-.07,5.09,0,7.64,0s5.09-.05,7.63,0a7.39,7.39,0,1,1,0,14.77Z"/>
      <path fill="#f5f5f5" d="M310.36,220.31c-6.82.09-13.65.11-20.48,0-1.86,0-2.27.45-2.26,2.28.08,15.22.05,30.43.05,45.65s0,30.6,0,45.9c0,1.61.41,2,2,2q10.35-.13,20.72,0c1.63,0,2-.44,2-2q-.08-45.9,0-91.8C312.38,220.71,312,220.28,310.36,220.31Z"/>
      <path fill="#f5f5f5" d="M270,365.39c-23.34.13-46.68,0-70,.12-9.42.06-18.21-1.58-26.17-7.49-1.23,12.32,8.32,24,20.88,24.14,25.47.27,50.95.07,76.43.13,1.65,0,1.84-.57,1.81-2-.09-3.94-.17-7.89,0-11.83C273,366.15,272.57,365.38,270,365.39Z"/>
      <path fill="#f5f5f5" d="M270.43,397c-23.26.09-46.51-.06-69.77.11-9.65.07-18.73-1.4-26.91-7.52-1.16,12.67,8.62,24,20.92,24.16,25.47.26,51,.07,76.43.13,1.68,0,1.81-.63,1.79-2-.08-4.1-.15-8.22,0-12.32C273,397.58,272.55,397,270.43,397Z"/>
      <path fill="#f5f5f5" d="M289.38,205.55c3.53,0,7.07,0,10.61,0s7.23,0,10.85,0c1.16,0,1.55-.27,1.53-1.49q-.09-8.38,0-16.77c0-1.16-.28-1.54-1.49-1.53q-10.86.07-21.71,0c-1.15,0-1.55.27-1.53,1.49.06,5.59.09,11.18,0,16.77C287.6,205.53,288.27,205.56,289.38,205.55Z"/>
      <path fill="#f5f5f5" d="M310.86,350.68c1.18,0,1.52-.32,1.51-1.51q-.09-8.39,0-16.77c0-1.19-.32-1.54-1.51-1.51-3.62.07-7.24,0-10.86,0s-7.23,0-10.85,0c-1.18,0-1.52.32-1.51,1.51q.09,8.39,0,16.77c0,1.19.33,1.52,1.51,1.51Q300,350.61,310.86,350.68Z"/>
      <path fill="#f5f5f5" d="M310.79,365.43c-7.16.08-14.31.06-21.47,0-1.19,0-1.72.19-1.69,1.58q.15,6.78,0,13.57c0,1.52.55,1.71,1.84,1.69,3.45-.08,6.91,0,10.36,0,3.62,0,7.24,0,10.85,0,1.2,0,1.73-.18,1.7-1.58-.1-4.52-.07-9,0-13.57C312.38,365.92,312.17,365.42,310.79,365.43Z"/>
      <path fill="#f5f5f5" d="M310.72,397c-3.54.08-7.08,0-10.61,0s-7.24.06-10.86,0c-1.33,0-1.63.41-1.61,1.66.07,4.53.08,9.05,0,13.57,0,1.34.41,1.63,1.66,1.62q10.74-.09,21.46,0c1.33,0,1.64-.4,1.62-1.66-.07-4.52-.08-9,0-13.56C312.4,397.31,312,397,310.72,397Z"/>
    </SVG>
  )
}

export default Cash
